html {
    font-size: 62.5%;
    -webkit-font-smoothing: antialiased;
}

body {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    font-size: 1.4rem;
    color: $gray-d2;
    background-color: $white-d1;
    min-height: 100vh;
    position: relative;
    &.ReactModal__Body--open {
        overflow-y: hidden;
        position: fixed;
    }
    &.searchresults{
        position: fixed;
    }
    &.searchresults{
        &:not(.page-homepage){
            .content{
                opacity: .3;
            }
        }
    }
    &.dark-mode{
        &.page-songbookpage{
            .songbooks{
                .new-songbook{
                    background-color: transparent !important;
                }
            }
        }
        .songbooks-search-container{
            input.private{
                color: $white;
            }
            svg{
                path{
                    fill: $white;
                }
            }
        }
        .navbar-brand{
            path{
                fill: $white;
            }
        }
        background-color: $black-mode-bg;
        h1, h2, p, div, a, button, span, select, option {
            color: $white !important;
        }
        label{
            color: $gray !important;
        }
        select, select:focus {
            background-color: $gray-d2;
        }
        .select-wrapper{
            &::after{
                background-image: url(../img/white_arrow_down.svg) !important;
            }
            select{
                background-color: $gray-d2;
            }
        }
        .white{
            svg{
                path{
                    fill: $white;
                }
            }
        }
        .dropdown-menu{
            background-color: $black-mode-bg-nav;
            button{
                cursor: pointer;
                &:hover{
                    background-color: transparent;
                }
            }
        }
        .playercontainer{
            #position-start, #position-stop{
                background-color: $green;
            }
            .chords-image{
                img{
                    background-color: $green;
                    border-radius: 4px;
                }
            }
            label{
                color: $black !important;
            }
            button{
                background-color: $green;
            }
        }
        .white{
            .author-container{
                background-color: $gray-d2 !important;
            }
        }
        .white{
            color: $white !important;
        }
        .dark-bg{
            background-color: $black-mode-bg !important;
        }
        .gray-bg{
            background-color: $gray-d2 !important;
        }
        .dark-font{
            color: $gray-d2 !important;
        }
        & > .song{
            background-color: $black-mode-bg-nav;
        }
        footer input.searchbox{
            background-color: $red-dark-mode !important;
            &:focus{
                background-color: $red !important;
            }
        }
        .cards-block{
            h3{
                color: $red !important;
            }
            .read-more{
                background-color: $red !important;
            }
        }
        .search-results{
            .song-list{
                input[type="checkbox"]{
                    &:checked + label {
                        background-color: $red !important;
                    }
                }
            }
        }
        .song-add-to-songbook{
            button{
                background-color: $red !important;
                border-color: $red !important;
            }
            .input.checked{
                &::before{
                    background-color: $red !important;
                }
            }
        }
        .tag-container{
            .tag-container--tag{
                background-color: $red !important;
            }
        }
        .new-book{
            padding: 0 !important;
            svg{
                path{
                    fill: $white !important;
                    stroke: $white !important;
                }
            }
            .create-new-songbook{
                label{
                    color: $white !important;
                }
                button#save{
                    background-color: transparent !important;
                }
            }
            .new-songbook.dark-bg{
                width: 100%;
                height: 42px;
                @include media-breakpoint-down(md){
                    &.add-new-open{
                        height: auto;
                    }
                }
                .add-songbook{
                    align-items: center;
                    padding: 0 0 0 12px !important;
                    height: 100%;
                    svg{
                        margin-left: 0 !important;
                    }
                }
            }
        }
    }
    & > .song{
        background-color: $gray-l2;
        max-width: 50%;
        padding: 0 20px;
        @include media-breakpoint-down(lg){
            max-width: 100%;
        }
        .title-col{
            max-width: 34%;
            .song-title{
                a{
                    color: $gray-d2;
                }
            }
        }
        .tones{
            max-width: 12.5%;
        }
        .chords-col{
            max-width: 37.5%;
        }
        .actions{
            max-width: 12.5%;
            .trash{
                background: transparent;
                border: none;
            }
        }
    }
}
.ReactModal__Overlay{
    z-index: 100;
    .nav{
        span.lang{
            line-height: 20px;
        }
    }
    body.dark-mode & {
        background-color: rgba(0, 0, 0, 0.75) !important;
    }
}
body > .content {
    min-height: 55vh;
    padding-bottom: 5vh;
}

#djDebug #djDebugToolbarHandle{
    bottom: 30px;
    top: auto!important;
}

h1 {
    font-size: 48px;
    font-size: 2.4rem;
    color: $black;
    &.stylish {
        font-family: "Playfair Display";	
        font-size: 3.6rem;	
        font-weight: 900;	
        line-height: 4.8rem;	
        text-shadow: 0 2px 5px 0 rgba(0,0,0,0.5);
        margin-bottom: 2rem;
    }
}

h2 {
    font-size: 30px;
    font-size: 3rem;
    line-height: 36px;
    line-height: 2;
    font-weight: 900;
    color: black;
}

a{
    &:hover{
        text-decoration-color: $green-d1;
    }
}
.container.wide{
    max-width: 1440px!important;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-right: 15px;
    padding-left: 15px;
}

.btn {
    &:focus{
        box-shadow: none;
    }
}

.btn-outline{
    font-size: 1.3rem;
    border: 1px solid $blue;
    border-radius: 4px;
    background: transparent;
    color: $blue;
    body.dark-mode & {
        color: $gray;
        border-color: $gray;

        & svg path {
            fill: $gray;
        }
    }
}

.modal-opener.button-modal-subscription, .modal-opener.button-modal-login, .buy-button {
    height: 65px;
    width: 238px;
    border-radius: 32.5px;
    background-color: #EC4365;
    color: $white;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-weight: 700;
    line-height: 65px;
    text-align: center;
    text-transform: uppercase;
    border: none;
    padding: 0;
}

button, input {
    &:focus {
        outline: none !important;
    }
}

.hidden {
    display: none;
  }

  .wagtail-userbar{
      right: 7em !important;
      bottom: 1em !important;
  }
  
  #wagtail-userbar-trigger{
    font-size: 1.6rem!important;
  }  

  body.dark-mode {
    input#private::placeholder {
        color: $white;
    }
}
