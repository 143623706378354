
  .progress-bar {
    height: 40px;
    width: 100%;
    background-color: #e0e0de;
    border-radius: 20px;
    margin: 50px 0;

    &-inner {
        height: 100%;
        background-color: #61AEB7;
        text-align: right;
        border-radius: inherit;
        position: relative;
        transition: width 1s ease-in-out;
    }
    &-completed  {
        padding: 5px;
        color: white;
        font-weight: bold;
        position: absolute;
        top: 5px;
        right: 10px;
    }
}
