.page-lessondetailpage{
    .playercontainer{
        padding-bottom: 100px;
        h2{
            font-size: 2rem;
            margin-top: 40px;
            .subtext{
                font-size: 1.4rem;
                font-weight: 900;
                float: right;
                margin-top: 10px;
                text-decoration: none;
                border: none;
                background-color: transparent;
                outline: none;
                color: $black;
                cursor: pointer;
            }
        }
        .lyrics-container.lesson{
            .lyrics{
                max-height: 300px;
                overflow: scroll;
                background-color: $white;
                &.song-detail{
                    margin-top: 0;
                    padding: 10px;
                }
        
                .chordline{
                    font-size: 1.4rem;
                    span{
                        @include media-breakpoint-down(md){
                            display: unset;
                            margin-top: 0;
                        }
                        strong{
                            top: -0.4em;
                            text-transform: uppercase;
                            color: $blue;
                            span {
                                display:none;
                            }
                        }
                    }
                }
            }
        }
        #chords{
            #chords-dialog{
                height: 0;
                overflow: hidden;
                margin-top: 20px;
                &.show{
                    height: auto;
                }
                ul{
                    display: flex;
                    flex-wrap: wrap;
                    padding-left: 0;
                    li{
                        list-style: none;
                    }
                }
            }
        }
        label {
            background-color: $gray-l1;
            cursor: pointer;
            &:after{
                height: 12px;
                width: 6px;
                display: block;
                transform: rotate(45deg);
                border-right: 2px solid $white;
                border-bottom: 2px solid $white;
                position: absolute;
                left: 7px;
                top: 2px;
            }
            span{
                margin-left: 25px;
                display: block;
                width: 100px;
            }
        }
        input[type="checkbox"], input[type="radio"]{
            display: none !important;
            &:checked + label {
                background-color: $green;
                border: none;
                &:after {
                    content: "";
                }
            }
            position: relative;
            @include media-breakpoint-down(md) {
                left: 15px;
            }
        }
        .video-container{
            margin-bottom: 30px;
            #mediaplayer{
                margin-left: auto;
                margin-right: auto;
                max-width: 100%;
                background-color: transparent !important;
                @include media-breakpoint-down(sm){
                    height: 550px !important;
                }
            }
        }
        #loop-controls{
            margin-top: 20px;
            input.loop-check[type="checkbox"]:checked{
                & + .loop-label{
                    color: $white;
                    font-weight: 700;
                }
            }
            .loop-label{
                padding: 6px 10px;
                &::after{
                    content: none;
                }
            }
        }
        #toolbar{
            #buttons{
                margin: 5px 20px 0;
                text-align: center;
                button{
                    margin-right: 16px;
                    &#play{
                        &.pressed{
                            opacity: 0;
                        }
                    }
                    &#pause{
                        display: none;
                        &.show{
                            display: block;
                            position: absolute;
                            left: 44%;
                            top: 6px;
                            z-index: -1;
                            display: block !important;
                        }
                    }
                }
                img{
                    height: 16px;
                }
                svg {
                    height: 16px;
                }
            }
        }
        #radio{
            float: right;
        }
        #radio, #loop-controls{
            display: inline;
            label{
                border-radius: 8px;
                text-align: center;
                margin-right: 10px;
                &.ui-state-active{
                    color: $white;
                    font-weight: 700;
                }
                span{
                    margin-left: unset; 
                }
            }
        }
        #scroll-container{
            display: none;
        }
    
        .ui-slider { 
            position: relative; 
            text-align: left; 
            margin-top: 30px;
            overflow: hidden;
            height: 24px;
            cursor: pointer;
            &::after{
                content: '';
                border-bottom: 4px solid $green-d1;
                position: absolute;
                left: 0;
                top: 4px;
                height: 6px;
                width: 100%;
                cursor: pointer;
            }
            &:hover{
                #position-current{
                    &::before{
                        content: '';
                        position: absolute;
                        height: 16px;
                        width: 16px;
                        border-radius: 50%;
                        background-color: $green-d1;
                        z-index: 10;
                        transition: height linear .3s, width linear .3s;
                    }
                }
            }
        }
        .ui-slider .ui-slider-handle { position: absolute; z-index: 2; height: 20px; cursor: default; }
        .ui-slider .ui-slider-range { position: absolute; z-index: 1; font-size: .7em; display: block; border: 0; background-position: 0 0; }
    
        .ui-slider-horizontal .ui-slider-handle { 
            background: $black;
        }
        .ui-slider-handle{
            &#position-current{
                &:after{
                    content: '';
                    position: absolute;
                    width: 100vw;
                    border-bottom: 4px solid $gray-l3;
                    height: 6px;
                    left: 100%;
                    top: 4px;
                }
            }
            &#position-start{
                width: 3px;
                top: -3px;
            }
            &#position-stop{
                width: 3px;
                top: -3px;
                margin-left: -3px;
            }
        }
        .ui-slider-horizontal .ui-slider-range { 
            top: 0; 
            height: 100%;
        }
        .ui-slider-horizontal .ui-slider-range-min { left: 0; }
        .ui-slider-horizontal .ui-slider-range-max { right: 0; }
        .chapters-container{
            #chapters{
                ul{
                    padding-left: 0;
                    display: flex;
                    flex-wrap: wrap;
                    li{
                        list-style: none;
                        border-radius: 18px;
                        padding: 8px 10px;
                        background-color: $green;
                        color: $white;
                        padding: 4px 12px;
                        text-align: center;
                        min-width: 80px;
                        margin-bottom: 10px;
                        margin-right: 8px;
                        cursor: pointer;
                        &:hover{
                            background-color: $green-d1;
                            transition: background-color linear .2s;
                        }
                    }
                }
            }
        }
    }
}