.ReactModal__Overlay {
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-down(md) {
        z-index: 100 !important;
        margin-top: -8px;
    }
}
body.dark-mode {
   .modal-chordchooser {
        background-color: $gray-d2;
        .text-container {
            button.close span {
                background-color: $white;
            }
        }

   }
}
body.page-songpage.dark-mode{
    .modal-chordchooser{
        svg{
            #grid line{
                stroke: $gray;
            }
            #dots circle{
                fill: $gray;
            }
            #dots text{
                fill: $gray;
            }
            #X text{
                fill: $gray;
            }
        }
    }
}
.modal-chordchooser {
    padding: 20px;
    overflow: scroll;
    background-color: $white;
    position: relative;
    width: 50vw;
    height: 50vh;
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.15);
    z-index: 10;
    border: $black;
    border-radius: 4px;

    .chord-container {
        &:after {
            content: "";
            flex: auto;
        }
    }

    @include media-breakpoint-down(lg){
        width: 75vw !important;
    }
    @include media-breakpoint-down(md) {
        width: 85vw !important;
        height: 62vh !important;
    }
    .user-pref{
        margin-top: 20px;
    }
    button.close {
        width: 20px;
        height: 20px;
        opacity: 1;
        transform: translate(32px, -20px);
        position: sticky;
        top: 20px;
        span {
            width: 20px;
            height: 2px;
            display: block;
            margin-bottom: 6px;
            z-index: 2;
            background-color: $black;
            position: absolute;
            &:nth-child(1) {
                transform: rotate(45deg);
            }
            &:nth-last-child(1) {
                transform: rotate(-45deg);
            }
        }
    }
    .text-container {
        position: relative;
        padding: 20px 30px;
    }
}