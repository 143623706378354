.submit-a-song{
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
    }
    .title{
      font-family: 'Playfair Display';
      margin-top: 50px;
    }
    .chordline{
      word-break: break-all;
    }
    .submit-song-form {
      flex-direction: column;
      .error{
        color: $error;
      }
      input, textarea{
        width: 100%;
        border-radius: 3.2px;
        body.dark-mode & {
          background-color: $gray-d2;
          color: $white;
        }
      }
      label{
        margin-bottom: 0;
      }
      input{
        height: 30px;
        border: 1px solid $gray;
        margin-bottom: 30px;
      }
      textarea{
        height: 300px;
        font-family: 'Courier New', Courier, monospace !important;
      }
      .submit-song{
        padding: 12px 20px;
        font-size: 14px;
        color: $white;
        background-color: $red;
        border-radius: 30px;
        font-weight: 700;
        margin: 30px auto;
      }
    }

}