.page-homepage{
    .header-image{
        width: 100vw; 
        height: 100vh;
        background-size: cover;
        background-position: center bottom;
        position: relative;
    }
    .buy-button{
        position: absolute;
        left: 50%;
        bottom: 15%;
        transform: translateX(-50%);
    }
    .scrolldown{
        width: 24px;
        height: 24px;
        border-bottom: 2px solid $white;
        border-left: 2px solid $white;
        transform: translateX(-50%) rotate(-45deg);
        transform-origin: center;
        position: absolute;
        left: 50%;
        bottom: 8%;
        cursor: pointer;
    }
}