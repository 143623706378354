.tag-container {
    padding-top: 2em;
    padding-bottom: 2em;
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    a {
        &.tag-container--tag {
            max-height: 30px;
            background-color: $green;
            color: $white;
            padding: 5px;
            border-radius: 4px;
            margin-right: 1em;
            margin-bottom: 8px;
        }
    }
}
