.favourites{
    position: absolute;
    left: -25px;
    top: 50%;
    transform: translateY(-50%);
    outline: none!important;
    box-shadow: none!important;
    background: transparent;
    width: 31px;
    height: 31px;
    padding: 0;
    transition: all .5s ease-in-out;
    @include media-breakpoint-down(xs) {
        position: absolute;
        right: -20px;
        top: 5px;
        transform: translateY(0px);
        transition: none;
        left: unset;        
    }
    svg {
        transition: all .5s ease-in-out;
    }
    .favoured{
        svg path{
            fill: $red;
        }
    }
}

body.dark-mode{
    .favourites{
        svg path{
            stroke: $gray;
        }
        .favoured{
            background: transparent;
            svg path{
                fill: $red;
                stroke: $red;
            }
        }
    }
}