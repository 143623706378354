.view-mode{
    .btn-mode{
        height: 70px;
        width: 117px;
        background-position: center!important;
        background-repeat: no-repeat!important;
        border: none;
        outline: none;
        margin-top: 10px;
        cursor: pointer;
        &.light-mode{
            background: url('../img/pick.svg') $gray-l1;
            margin-right: 50px;
        }
        &.dark-mode{
            background: url('../img/pick-inverted.svg') $black;
        }
        &.active{
            border: 1px solid $red;
            position: relative;
            &:before{
                content: '';
                background: url('../img/checkmark.svg') $red;
                background-repeat: no-repeat;
                background-position: center;
                border-radius: 2px;
                height: 29px;
                width: 29px;
                background-size: 14px;
                position: absolute;
                left: -6px;
                bottom: -3px;
            }
        }
    }
}
body.page-songpage{
    background-color: $white-d2;
}
body.page-songpage.dark-mode{
    -webkit-font-smoothing: antialiased;
    background-color: $black-mode-bg;
    .song{
        color: $gray;
    }
    h2.title{
        color: $gray;
    }
    .chord-container{
        svg{
            #grid line{
                stroke: $gray;
            }
            #dots circle{
                fill: $gray;
            }
            #dots text{
                fill: $gray;
            }
            #X text{
                fill: $gray;
            }
        }
    }
}