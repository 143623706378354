.modal-subscription {
    .nav {
        justify-content: space-between;
        padding: 31px 40px;
        top: 0px;
        position: absolute;
        width: 100%;
        z-index: 10;
        @include media-breakpoint-down(lg) {
            top: 60px;
        }
        @include media-breakpoint-down(sm) {
            padding: 31px 25px 80px 25px;
            margin-top: 50px;
            top: 20px;
        }
        .lang {
            font-size: 1.9rem;
            line-height: 2.6rem;
            color: $white;
            cursor: pointer;
        }
        button.close {
            width: 30px;
            height: 30px;
            opacity: 1;
            transform: translateY(-3px);
            span {
                width: 30px;
                height: 2px;
                display: block;
                margin-bottom: 6px;
                z-index: 2;
                background-color: $white;
                position: absolute;
                &:nth-child(1) {
                    transform: rotate(45deg);
                }
                &:nth-last-child(1) {
                    transform: rotate(-45deg);
                }
            }
        }
    }
    .modal-body {
        border: none;
        max-width: 620px;
        margin: 0 auto;
        width: calc(100% - 40px);
        padding-top: 80px;

        .subscription-description {
            color: $white;
        }
        .subscription-interval {
            color: $white;
             a {
                font-size: 16px;
                color: $white;
                border-radius: 4px;
                padding: 10px;
                border: 1px solid $white;
                cursor: pointer;
                &.active {
                    border: 1px solid $red-dark-mode;
                    background-color: $red-dark-mode;
                }
            }
        }
        .logo-container {
            width: 200px;
            margin: 0 auto;
            display: block;
            .logo {
                fill: white;
            }
        }
        .modal-title {
            font-size: 15px;
            font-weight: 800;
            line-height: 20px;
            color: $white;
            margin-top: 100px;
            text-transform: uppercase;
        }
        .form-group {
            position: relative;
            padding-top: 36px;
            &:nth-child(3) {
                margin-top: 44px;
            }
            label {
                position: absolute;
                font-size: 24px;
                font-weight: 200;
                line-height: 38px;
                bottom: 0;
                color: $white;
                &.animate-label {
                    top: 0px;
                    bottom: unset;
                    font-size: 16px;
                }
            }
            input {
                color: $white!important;
                border: none;
                border-radius: 0;
                border-bottom: 1px solid $white;
                padding-left: 0;
                height: 44px;
                background-color: transparent!important;
                font-size: 24px;
                font-weight: 200;
                line-height: 38px;
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover, 
            input:-webkit-autofill:focus {
                border: none none solid none;
                -webkit-text-fill-color: $white;
                -webkit-box-shadow: 0 0 0px 1000px $gray-d2 inset;
                transition: background-color 5000s ease-in-out 0s;
            }

            .form-control:focus {
                box-shadow: none;
            }
        }
    }
}
