.cards-block{
    margin-top: 25rem;
    @include media-breakpoint-down(sm) {
        padding: 0px;
    }
    .card{
        background: $white;
        box-shadow: 0 10px 30px 0 rgba(0,0,0,0.10);
        margin-bottom: 5rem;
        &.come-in {
            transform: translateY(300px);
            opacity: 0;
            animation: come-in 1.5s ease forwards;
        }
        &.come-in:nth-child(odd) {
            animation-duration: 0.8s; /* So they look staggered */
        }
          
        @keyframes come-in {
            to { 
                transform: translateY(0); 
                opacity: 1;
            }
        }

        .card-img{
            width: 100%;
            margin-left:-2rem;
            margin-top: 2rem;
            @include media-breakpoint-down(xs) {
                margin: 20px auto 0px!important;
                max-width: calc(100% - 40px);
            }
        }
        &.odd{
            .card-img{
                margin-left: auto;
                margin-right: -2rem;
            }
            &:first-of-type{
                margin-top: 16rem;
                @include media-breakpoint-down(md) {
                    margin-top: 0px;
                }
            }
        }
        .text{
            padding: 3rem 4.5rem 0 4.5rem; 
            h2{
                font-family: 'Playfair Display', serif;
                font-weight: 900;
                font-size: 3.6rem;
                line-height: 1;
                margin: 0;
            }
            h3{
                font-family: 'Playfair Display', serif;
                font-weight: 900;
                font-size: 2.4rem;
                line-height: 1.5;
                color: $green;
            }
            .rich-text{
                font-size: 1.6rem;
                line-height: 2.6rem;
                margin-bottom: 3rem;
                p{
                    margin-top: 1rem;
                }
            }
            .read-more{
                display: block;
                font-size: 1.4rem;
                font-weight: 700;
                text-transform: uppercase;
                color: $white;
                height: 5.5rem;
                width: 18.7rem;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 3rem;
                text-align: center;
                border-radius: 2.75rem;
                line-height: 5.5rem;
                background: $green;
                cursor: pointer;
                text-decoration: none;
            }
        }
    }
}