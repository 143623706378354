.share-qr-inner-container {

    .btn-icon-after {
        background-color: $green;
        border-color: $green;
        color: $white;
        position: relative;
        min-width: 42px;
        height: 42px;
        width: 100%;
        transition: width .2s linear;
        overflow: hidden;
        z-index: 1;
        text-align: left !important;

        // span {
        //     display: none;
        // }

        &:focus {
            outline: none;
            box-shadow: none;
        }

        .add {
            font-weight: 600;
        }

        .svg-container {
            border-radius: 4px;
            width: 40px;
            height: 40px;
            background: $green;
            display: flex;
            justify-content: center;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        svg {
            width: 20px;

            g {
                path {
                    &:nth-child(1) {
                        fill: $white;
                    }

                    &:nth-child(2) {
                        stroke: $white;
                    }
                }

                circle {
                    fill: unset;
                }
            }
        }
    }
}


body.dark-mode {
    .share-qr-inner-container {
        .btn-icon-after {
            background-color: $red;
            border: none;

            .svg-container {
                background-color: $red;
                border: none;
            }
        }

    }

    .btn-song-controls {
        svg {
            g {
                path {
                    fill: unset;
                }
            }
        }
    }
}


@include media-breakpoint-up(lg) {
    .share-qr-inner-container {
        .btn-icon-after {
            // &:hover {
            //     transition: width .2s linear;
            //     padding-right: 40px;
            //     width: 100%;


            //     span {
            //         display: block;
            //         white-space: nowrap;
            //     }
            // }
        }
    }
}

@include media-breakpoint-down(md) {
    .share-qr-inner-container {
        .btn-icon-after {
            padding: 16px;

            .svg-container {
                display: flex;
                align-items: center;
                justify-content: center;
                transform: none;
                border: none;
                top: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}