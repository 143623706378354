.rel-song-container{
    margin-top: 120px;
    @include media-breakpoint-down(md){
        margin-top: 80px;;
    }
    .title{
        margin-bottom: 30px;
        font-weight: 600;
        font-size: 25px;
        @include media-breakpoint-down(md){
            font-size: 22px;
        }
    }
}
.all-song-container{
    @include media-breakpoint-down(lg){
        flex-wrap: wrap;
    }
}
.rel-song{
    position: relative;
    width: 100%;
    margin-left: 8px;
    padding-left: 0;
    margin-right: 8px;
    height: 100%;
    background-color: $white;
    body.dark-mode & {
        background-color: $gray-d2;
    }
    border-radius: 8px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    @include media-breakpoint-down(lg){
        margin-left: 0px !important;
        height: auto;
        margin-bottom: 20px;
    }
    body.dark-mode & {
        background-color: $gray-d2;
    }
    &.less{
        padding: 12px;
    }
    .img-container{
        min-width: 120px;
        width: 120px;
        height: 100px;
        border-radius: 8px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        overflow: hidden;
        margin-right: 20px;
        img{
            height: 100%;
            width: auto;
            object-fit: cover;
            object-position: 100% 50%;
        }
    }
    .no-img{
        height: 70px;
        padding-left: 16px;
    }
    .song-title-container{
        max-height: 100px;
        overflow: hidden;
        padding-top: 10px;
        a{
            font-size: 16px;
            line-height: 22px;
            color: $gray-d2;
        }
        p{
            a{
                font-size: 12px;
            }
        }
    }
}