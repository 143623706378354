.chordline {
	line-height: 3.5em;
	font-weight: 400;
	@include media-breakpoint-down(sm) {
		white-space: normal;
	}
	&.empty {
		height: 20px;
	}
	span {
		position: relative;
		strong {
			font: bold 70%/1 sans-serif;
			display: inline;
			position: absolute;
			top: -0.9em;
			left: 0;
			color: $black;
			font-size: 1em !important;
			font-weight: 700;
			white-space: nowrap;
			span {
				display: none;
			}
			&.open-chords {
				cursor: help;
			}
		}
	}
	.chords-false & {
		margin-top: 0;
		line-height: 1.5;
		span {
			strong {
				display: none;
			}
		}
	}
}
