.show-chords-switch{
    position: relative;
    width: 39px;
    height: 12px;
    background-color: $white;
    border-radius: 6px;
    float: right;
    margin-top: -15px;
    cursor: pointer;
    &:before{
        content: '';
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: $blue;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
    &.state-true{
        &:before{
            left: auto;
            right: 0;
        }
    }
    &.state-false{
        background-color: $gray-d1;
        &:before{
            left: 0;
            right: auto;
        }
    }
    input{
        display: none;
    }
}