.footer {
    padding: 170px 120px 40px 120px;
    background-color: $gray-d2;
    width: 100%;
    @include media-breakpoint-down(md){
        padding: 40px 0;
        .links{
            padding: 0;
        }
    }
    .links {
        ul{
            padding-left: 0;
            li{
                list-style: none;
                margin-bottom: 0 ;
            }
        }
    }
    .terms, a {
        color: $white;
        font-size: 18px;
        line-height: 32px;
        margin-bottom: 0px;
        text-decoration: none;
        position: relative;
        @include media-breakpoint-down(sm){
            font-size: 14px;
        }
    }
    .terms{
        margin-top: 100px;
        @include media-breakpoint-down(sm){
            font-size: 10px;
            a{
                font-size: 10px;
            }
            .seperator{
                margin-right: 34px !important;
            }
        }
        .seperator{
            margin-right: 40px;
            position: relative;
            &::after{
                content: '';
                position: absolute;
                top: 6px;
                right: -20px;
                width: 1px;
                height: 18px;
                background-color: $white;
            }
        }
    }
    .chords-col{
        @include media-breakpoint-down(md){
            margin-top: 70px;
        }
        h3{
            color: $white;
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 20px;
        }
        p{
            color: $white;
        }
        input {
            width: 100%;
            border: none;
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
            outline: none;
            background-color: transparent;
            &::placeholder{
                color: $black;
                opacity: 1;
            }
            @include media-breakpoint-down(sm){
                margin-left: 8px;
                font-size: 12px;
            }
            &.searchbox{
                background-color: $green;
                border-radius: 30px;
                color: $white;
                padding-left: 20px;
                height: 48px;
                margin-top: 40px;
                &:focus{
                    background-color: $green-90;
                }
            }
            &.button{
                position: absolute;
                color: white;
                width: auto;
                right: 7%;
                font-size: 16px;
                bottom: 9%;
                padding: 0;
                font-weight: 400;
                border-bottom: 2px solid transparent;
                cursor: pointer;
                &:hover{
                    font-weight: 700;
                }
            }
        }
    }
}