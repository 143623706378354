.page-songlistpage{
    .song{
        padding-bottom: 1.2rem;
        border-bottom: 1px solid $gray-l1;
        .title{
            font-weight: 700;
            color: $black;
            font-weight: bold;
            font-size: 1.8rem;
        }
        .song-excerpt{
            font-weight: regular;
            font-size: 1.5rem;
            color: $gray-d1;
            overflow: hidden;
            width: 100%;
            height: 60px;
            line-height: 1.3;
            span{
                color: $green;
            }
        }
    }
}