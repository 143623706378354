.alphabet{
    overflow-x: scroll;
    ul{
        padding: 0;
    }
    li{
        list-style: none;
        padding-right: 10px;
        a {
            color: $chord-color-blue;
            font-weight: bold;
            text-decoration: none;
            &:hover {
                opacity: .5;
            }
        }
    }
}