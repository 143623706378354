.tones {
    .btn-tones-sub {
        border: 1.2px solid $gray-d2-70;
        border-radius: 4.8px;
        font-size: 14px;
        padding: 5px 9px;
        min-width: 50px;
        @include media-breakpoint-down(sm) {
            min-width: unset;
            width: 44px;
        }
        &.transposed {
            background-color: $red;
            border-color: $red;
            color: $white-d1;
        }
    }
    .tones-sub {
        width: 100%;
        position: absolute;
        display: none;
        color: black;
        background-color: white;
        top: 100%;
        left: 17px;
        flex-wrap: wrap;
        padding: 16px 20px;
        justify-content: space-between;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        z-index: 1;
        @include media-breakpoint-down(sm) {
            width: calc(100vw - 34px);
            max-width: 235px;
        }
        @media (max-width: 506px) {
            left: unset;
            right: 0px;
        }
        p {
            margin: 0px;
            padding: 8px;
        }

        &.open {
            display: flex;
            min-width: 170px;
            &::before {
                content: "";
                position: absolute;
                top: -8px;
                left: 8px;
                border-style: solid;
                border-width: 3px 8px 8px 8px;
                border-color: transparent transparent $white transparent;
            }
        }
        .btn-tones {
            border: none;
            color: $gray-d2;
            width: 30px;
            height: 30px;
            padding: 0;
            margin-left: auto;
            margin-right: auto;
            &:hover,
            &.active {
                background-color: $red;
                color: $white;
            }
            &.original {
                border: 1px solid $gray-d2;
            }
        }
    }
}

.dark-mode {
    .songbook-detail .tones {
        .btn-tones-sub {
            &.transposed {
                background-color: $white-d1;
                border-color: $white-d1;
                color: $gray-d2 !important;
            }
        }
    }

    .settings-bar .tones .tones-sub,
    .songbook-detail .tones .tones-sub {

        background-color: $gray-d2;
        .btn-tones {
            color: $white !important;
            width: 30px;
            height: 30px;
            padding: 5px 10px;
            margin-left: auto;
            margin-right: auto;
            &:hover,
            &.active {
                background-color: $red;
                color: $white;
            }
            &.original {
                border: 1px solid $white-d1;
            }
            button {
                color: $white !important;
            }
        }
        &.open {
            &::before {
                border-color: transparent transparent $gray-d2 transparent;
            }
        }
    }
}
