.ReactModal__Overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1440px){
      .nav{
          padding: 31px 40px 0;
      }   
  }
  @include media-breakpoint-down(md) {
      z-index: 100 !important;
      margin-top: -8px;
  }
}
body.dark-mode {
 .modal-receipt {
      background-color: $gray-d2;
      .text-container {
          button.close span {
              background-color: $white;
          }
      }

 }
}
.modal-receipt {
  overflow: scroll;
  background-color: $white;
  width: 50vw;
  position: relative;
  max-width: 550px;
  height: auto;
  max-height: 650px;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.15);
  z-index: 10;
  border: $black;
  @include media-breakpoint-down(xxl) {
      width: 80vw;
  }
  @include media-breakpoint-down(xl) {
      width: 100vw;
  }
  @include media-breakpoint-down(md) {
      width: 100%;
      height: 100%;
      max-height: unset;
      flex-direction: column;
  }
  .receipt {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    border-right: 1px solid black;
    border-left: 1px solid black;
    border-top: 1px dashed black;
    border-bottom: 1px dashed black;
    padding: 40px;
  }
  .text-container {
      button.close {
          opacity: 1;
          z-index: 100;
          top: 40px;
          position: sticky;
          span {
              width: 30px;
              height: 2px;
              display: block;
              margin-bottom: 6px;
              z-index: 2;
              background-color: $gray-d2;
              position: absolute;
              &:nth-child(1) {
                  transform: rotate(45deg);
              }
              &:nth-last-child(1) {
                  transform: rotate(-45deg);
              }
              @include media-breakpoint-down(md) {
                  background-color: $white;
              }
          }
      }
      padding: 40px;
      a {
          font-size: 20px;
          line-height: 50px;
          color: $gray-d2;
          @include media-breakpoint-down(md) {
              font-size: 18px;
          }
          &:hover {
              text-decoration: none;
              font-weight: 600;
          }
      }
  }
}