div.vex-tabdiv {
    font-family: Arial, sans-serif;
    font-size: 18px;
    color: #554;
    white-space: pre;
    overflow: scroll;
  }
  
  div.vex-tabdiv .vex-canvas {
    background: transparent;
    border: 0;
  }
  
  div.vex-tabdiv .editor {
    background: #cfc;
    border: 10px solid #afa;
    font-family: Courier;
    font-size: 14px;
  }
  
  div.vex-tabdiv .editor-error .text {
    background: #faa;
    border: 5px solid #f88;
    font-family: Courier;
    font-size: 14px;
    padding: 3px;
  }
  
  div.vex-tabdiv .title {
    font-family: Arial, sans-serif;
    font-size: 18px;
    padding: 10px;
    color: #554;
  }
  