.author-block-container{
  margin-top: 150px;
  height: auto;
  
  &.black {
    background-color: $black;
    color: $white;
    a {
      color: $white;
    }
  }

  &.white {
    .container {
      background-color: $white;
      color: $gray-d2;
      .img-container{
        @include media-breakpoint-up(xl){
          position: absolute;
          left: -10%;
          top: -6%;
        }
      }
      .bio-col{
        @include media-breakpoint-up(xl){
          margin-left: -3%;
        }
      }
      h1 {
        color: $gray-d2;
      }
      a {
        color: $gray-d2;
      }
      .more-bands{
        display: flex;
        padding: 0;
        justify-content: center;
        a {
          background-color: $gray-l2;
          &:hover {
            background-color: $red;
          }
        }
      }
    }
  }

  .container {
    padding: 64px 0px;
    height: auto;
    box-shadow: 0 10px 40px 0 rgba(0,0,0,0.15);
    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
    @include media-breakpoint-down(xs) {
      max-width: calc(100% - 40px);
    }
    .img-container {
      @include media-breakpoint-down(md) {
        max-width: 400px;
        margin: 0 auto;
      }

      img {
        width: 100%;
      }
    }
    .bio-container {
      @include media-breakpoint-down(md) {
        margin-top: 40px;
      }
    }
    .songs-container {
      .title-songs{
        padding-bottom: 16px;
        margin-bottom: 20px;
        border-bottom: 1px solid $gray-d3;
      }
    }
    
  }
  .img-container {
    img {
      box-shadow: 20px 20px 40px -20px rgba(0,0,0,0.75);
    }
  }
  .bio-container {
    p {
      font-family: "Nunito Sans";
      line-height: 26px;
    }
  }
  .songs-container {
    margin-top: 2em;
    .title-songs, p {
      border-bottom: 1px solid $gray-d2;
    }
  }
  .songlist-container {
    margin-bottom: 70px;
    flex-wrap: wrap;
    max-height: 160px;
    @include media-breakpoint-down(sm){
      flex-wrap: nowrap;
      max-height: unset; 
    }
    div{
      height: 37px;
      max-width: 50%;
      border-bottom: 1px solid $gray-d3;
      font-weight: 600;
      @include media-breakpoint-down(md){
        max-width: unset;
      }
      &.hide{
        display: none !important; 
      }
    }
  }
  h1 {
    color: $white;
    font-family: "Playfair Display";
    font-size: 4rem;
    font-weight: 900;
    line-height: 5.3rem;
    text-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
  }
  .bio-short {
    font-family: "Nunito Sans";
  }
  .more-bands {
    margin-top: 38px;

    a {
      color: $green;
      background-color: $white;
      padding: 17px 40px;
      font-weight: 900;
      border-radius: 31.5px;
      text-transform: uppercase;
      cursor: pointer;
      font-size: 1.4rem;
      &:hover {
        color: $white;
        background-color: $red;
      }
    }
  }
  p {
    font-size: 1.2em;
  }
}
