.settings-bar-container {
    height: 48px;

    @include media-breakpoint-down(md) {
        height: auto;
    }
}

.settings-bar {
    height: 48px;
    background-color: $gray-d2;
    border-radius: 8px;
    color: $white;
    position: relative;

    &.playsongbook-upper {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    &.playsongbook-lower {
        border-top: 1px solid $gray;
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        @include media-breakpoint-down(sm) {
            height: auto;

            .song-controls {
                line-height: 18px;
                padding-right: 8px;
                padding-left: 8px;
            }

            .playsongbook-songs {
                padding-left: 8px !important;
            }
        }
    }

    .playsongbook-songs {
        margin-bottom: 0;
        padding-left: 0;
        cursor: pointer;

        li {
            list-style: none;
            padding-left: 17px;
            padding-right: 17px;

            &.do-show {
                display: none;
            }
        }

    }

    .song-controls.scroll {
        @include media-breakpoint-down(md) {
            position: unset !important;
        }
    }

    div.song-controls.song-list[aria-expanded="true"] {
        background-color: $gray-d2;
        margin-top: 15px;
        align-items: flex-start;
        max-height: 500px;
        //height: 500px;
        overflow-y: scroll;
        padding-bottom: 20px;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        z-index: 1;
        line-height: 28px !important;
        padding-left: 0;
        padding-right: 0;

        span {
            margin: 0 auto 8px auto;
        }

        ul {
            overflow: scroll;
            width: 100%;

            li {
                display: none;

                &.active {
                    background-color: $white;
                    color: $gray-d2;
                    display: block;
                    font-weight: bold;
                }

                &.show,
                &.do-show {
                    display: block;
                }
            }
        }
    }

    &>.song-controls {
        display: flex;
        justify-content: center;
        align-items: center;

        @include media-breakpoint-down(sm) {
            font-size: 14px;
        }

        &.active {
            .svg-container {
                // background-color: $red;
                border: none !important;
            }
        }

        &.play {
            cursor: pointer;
            max-height: 47px;
        }

        &.chords {
            div {
                display: flex;

                p {
                    margin: 0px;
                }

                label {
                    margin: 4px 0px 0px 16px;
                }
            }

            .svg-container {
                svg {
                    path {
                        stroke: none !important;
                    }
                }
            }
        }

        &.tones {
            @media (max-width: 506px) {
                position: static;
            }

            // .svg-container {
            //     border: 1px solid;
            // }
        }

        border-right: none;

        @include media-breakpoint-up(sm) {
            border-right: 1px solid $gray-d4;;
            &:last-child {
                border-right: none;
            }
        }

        .svg-container.settings {
            svg {
                path {
                    fill: transparent;
                }
            }
        }

        .btn {
            padding: 0px;
            outline: none;
            background-color: transparent;
            outline: none !important;
            box-shadow: none !important;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            width: 100%;
            height: 100%;
            color: $white;
            font-size: 14px;
            position: relative;

            &:hover {
                color: $white;
            }

            @include media-breakpoint-down(sm) {
                &.modal-opener {
                    opacity: 0;
                    width: 0 !important;
                }
            }

            .svg-container {
                position: absolute;
                width: 30px;
                height: 30px;
                border-radius: 4px;
                padding: 5px;
                left: 0px;

                @include media-breakpoint-down(xs) {
                    position: static;
                }

                svg {
                    vertical-align: unset;

                    path,
                    circle {
                        stroke: $white;
                    }
                }
            }

            &>p {
                margin-left: 20px;
                margin-bottom: 0px;
            }
        }

        .song-controls {
            position: absolute;
            padding: 6px;
            background-color: $white;
            color: $gray-d2;
            box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.15);
            display: none;
            border-radius: 0px 0px 8px 8px;
            z-index: 2;
            left: 0px;
            top: 100%;
            width: calc(100vw - 34px);
            max-width: 350px;

            body.dark-mode & {
                border: 1px solid $gray;
                background-color: $black;
                color: $gray;

                .control {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                }
            }

            body.song-controls-open & {
                display: block;
            }

            p {
                margin: 0;
            }

            .control {
                padding: 15px 20px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);

                &:last-child {
                    border-bottom: 0;
                    padding-bottom: 0;
                }
            }

            .form-check {
                padding: 0;

                .form-check-label {
                    position: relative;
                    // padding: 0 0 0 20px;
                    padding: 0 0 0 28px;
                }
            }

            input[type="radio"] {
                display: none;
            }

            input[type="radio"]+label {
                width: 100%;
                height: 35px;
                line-height: 35px;
                padding-left: 10px;
                padding-right: 10px;
                cursor: pointer;
                opacity: 0.5;
            }

            input[type="radio"]+label:before {
                content: "";
                // border: 4px solid $white;
                width: 16px;
                height: 16px;
                border-radius: 10px;
                display: inline-block;
                background-color: $white;
                margin-right: 10px;
                opacity: 0.5;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                cursor: pointer;
            }

            input[type="radio"]+label:after {
                content: "";
                border: 1px solid #000;

                body.dark-mode & {
                    border: 1px solid $red;
                }

                width: 20px;
                height: 20px;
                display: block;
                border-radius: 50%;
                cursor: pointer;
                position: absolute;
                top: 50%;
                left: 0px;
                transform: translateY(-50%);
                opacity: 0.4;
            }

            input[type="radio"]+label span {
                float: right;
            }

            input[type="radio"]:checked+label {
                opacity: 1;
            }

            input[type="radio"]:checked+label:before {
                // border-color: $white;
                background-color: $red;
                opacity: 1;
                width: 10px;
                height: 10px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 5px;
            }

            input[type="radio"]:checked+label:after {
                opacity: 1;
            }

            .show-chords {
                display: flex;
                justify-content: space-between;

                &>span {
                    height: 16px;
                    width: 53px;
                    border-radius: 8px;
                    background-color: $gray-l1;
                    display: block;
                    position: relative;

                    &>span {
                        height: 24px;
                        width: 24px;
                        background-color: $red;
                        display: block;
                        border-radius: 50%;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 0px;
                    }
                }
            }

            .save-btn {
                button {
                    width: 100%;
                    height: 55px;
                    background-color: $red;
                    color: $white;
                    border-radius: 4px;
                    border: none;
                    font-size: 16px;
                    font-weight: bold;
                }
            }
        }
    }
}

body.song-controls-open {
    .settings-bar {
        border-radius: 8px 8px 8px 0px;

        @media (max-width: 384px) {
            border-radius: 8px 8px 0px 0px;
        }
    }
}