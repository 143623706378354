$hamburger-padding-x           : 15px !default;
$hamburger-padding-y           : 15px !default;
$hamburger-layer-width         : 30px !default;
$hamburger-layer-height        : 2px !default;
$hamburger-layer-spacing       : 5px !default;
$hamburger-layer-color         : $black !default;
$hamburger-layer-border-radius : 1px !default;
$hamburger-hover-opacity       : 0.7 !default;
$hamburger-active-layer-color  : $hamburger-layer-color !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter   : false !default;
$hamburger-hover-filter       : opacity(50%) !default;
$hamburger-active-hover-filter: $hamburger-hover-filter !default;

// Types (Remove or comment out what you don’t need)
$hamburger-types: (
  slider,
) !default;

@import '~hamburgers/_sass/hamburgers/hamburgers';

body.ReactModal__Body--open.page-homepage nav.navbar{
    z-index: 0;
}


/* Only homepage, all sizes */
.page-homepage {
    nav.navbar {
        // height: 90px;
        position: absolute;
        width: 100%;
        display: block;
        padding: 16px 0px;
        z-index: 2;
        &>.container-fluid {
            padding: 0px 25px;
        }
        .navbar-brand{
            position: absolute;
            left: 50%;
            transform: translate(-50%, -100%);
            transform-origin: bottom center;
            top: calc(100px + 28vh);
            svg{
                path{
                    fill: $white;
                }
            }
            .logo {
                &.desktop {
                    height: auto;
                    width: calc( 100vw / 1000 * 270);
                    max-width: 270px;
                    min-width: 160px;
                }
                &.mobile {
                    display: none;
                }
            }
        }
        .search-container{
            position: absolute;
            top: 0;
            left: 0;
            max-width: 100%;
            padding-left: 20px;
            padding-right: 20px;
            .search-wrapper{
                margin: 45vh auto 0px;
                max-width: 754px;
                background: transparent;
                width: 100%;
                @include media-breakpoint-down(sm){
                    &.results{
                        margin: 0;
                    }
                }
                .search-input{
                    overflow: hidden;
                    background-color: $white;
                    border-radius: 3rem;
                    box-shadow: 0 0 25px 0 rgba(0, 0, 0, .5);
                }
            }
        }
        .menu-wrapper{
            width: 100%;

            #menu{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .hamburger {
                    @include media-breakpoint-down(sm) {
                        padding: 0px;
                    }
                    .hamburger-box{
                        vertical-align: text-top;
                        .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after{
                            background-color: $white;
                        }
                    }
                }
                .lang, .login{
                    font-size: 1.9rem;
                    line-height: 2.6rem;
                    padding: 15px;
                    @include media-breakpoint-down(md){
                        padding: 0 15px;
                    }
                    span {
                        color: $white;
                        line-height: 52px;
                    }
                    button{
                        border: none;
                        background: transparent;
                        color: $white;
                        cursor: pointer;
                        font-size: 1.9rem;
                        width: auto;
                        height: auto;
                        text-transform: none;
                        font-weight: normal;
                        &.modal-opener {
                            @include media-breakpoint-down(sm) {
                                padding-right: 20px;
                            }
                        }
                    }
                    a {
                        color: $white;
                        &:hover {
                            text-decoration: none;
                        }   
                    }
                }
                .lang {
                    span{
                        cursor: pointer;
                    }
                    @include media-breakpoint-down(sm) {
                        padding: 15px 0px;
                    }
                }
            }
        }
        .search-mobile{
            display: none;
        }
    }
    &.menu-open {
        padding-top: 105px;
        nav.navbar{
            position: fixed;
            top: 0;
            width: 100%;
            .navbar-brand{
                display: none;
            }
        }
    }
}


/* All other pages desktop */
body:not(.page-homepage) {
    padding-top: 130px;
    @include media-breakpoint-down(md){
        padding-top: 110px;
        &.settings-bar-fixed{
            // padding-top: 145px ;
        }
    }
    @include media-breakpoint-between(sm, md){
        // padding-top: 76px;
    }
    @include media-breakpoint-down(sm){
        // padding-top: 100px;
    }
    nav.navbar{
        @include media-breakpoint-down(sm){
            max-width: 100vw;
        }
    }
    &.dark-mode {
        nav.navbar {
            background-color: $black;
            color: $gray;
            .navbar-brand{
                width: 68px;
                @include media-breakpoint-down(sm) {
                    width: auto;
                }
                .logo {
                    path {
                        fill: white;
                    }
                }
            }
            .hamburger {
                .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
                    background-color: $white;
                }
            }
        }
    }

    nav.navbar{
        position: fixed;
        top: 0;
        height: 105px;
        width: 100%;
        background-color: $white;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
        padding: 0px;
        z-index: 100;
        @include media-breakpoint-down(md){
            height: 60px;
        }
        .container-fluid {
            height: 100%;
            .navbar-brand{
                width: 68px;
                @include media-breakpoint-down(sm) {
                    width: auto;
                }
                .logo {
                    &.desktop {
                        display: block;
                    }
                    &.mobile {
                        display: none;
                    }
                    @include media-breakpoint-down(sm) {
                        &.desktop {
                            display: none;
                        }
                        &.mobile {
                            display: block;
                        }
                    }
                }
            }
            .search-container{
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                .search-wrapper{
                    background-color: $green;
                    border-radius: 25px;
                    height: 50px;
                    input{
                        font-weight: 600;
                        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                            color: $white;
                            opacity: 1; /* Firefox */
                        }
                          
                        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                        color: $white;
                        }
                          
                        &::-ms-input-placeholder { /* Microsoft Edge */
                        color: $white;
                        }
                    }
                }
                ul.toplists{
                    display: none;
                }
                .search-mobile{
                    display: none;
                }
            }
        }
    }
    .menu-wrapper {
        #menu {
            align-items: center;
            display: flex;
            .hamburger {
                @include media-breakpoint-down(sm) {
                    padding: 0px;
                }
            }
            .lang{
                display: none;
            }
            .login{
                display: none;
            }
        }
    }
    &.menu-open {
        nav.navbar{
            z-index: 10;
            .navbar-brand {
                display: none;
            }
            .menu-wrapper {
                width: 100%;
                #menu {
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                }
                .lang {
                    display: flex !important;
                    align-items: center;
                    cursor: pointer;
                    font-size: 1.9rem;
                    line-height: 2.6rem;
                    padding: 15px;
                    @include media-breakpoint-down(sm) {
                        padding: 15px 0px;
                    }
                    a, span {
                        color: $white;
                        cursor: pointer;
                        &:hover{
                            border-bottom: 1px solid $white;
                        }
                    }
                }
                .login{
                    display: block !important;
                    cursor: pointer;
                    @include media-breakpoint-down(sm){
                        margin-right: 20px;
                    }
                    span, a{
                        color: $white !important;
                        line-height: 30px;
                        font-size: 1.9rem;
                        &:hover{
                            border-bottom: 1px solid $white;
                            text-decoration: none;
                        }
                    }
                    button{
                        border: none;
                        background: transparent;
                        color: $gray-d2;
                        cursor: pointer;
                        font-size: 1.9rem;
                        width: auto;
                        height: auto;
                        text-transform: none;
                        font-weight: normal;
                        &.modal-opener {
                            @include media-breakpoint-down(sm) {
                                padding-right: 20px;
                            }
                        }
                    }
                }
                .hamburger {
                    .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
                        background-color: $white;
                    }
                }
            }
        }
    }
    &.ReactModal__Body--open {
        .nav {
            z-index: 10;
        }
        .lang {
            display: block;
        }
    }
    @include media-breakpoint-down(sm) {
        .menu-wrapper {
            .menu {
                .login{
                    display: none;
                }
            }
        } 
        &.menu-open {
            .menu-wrapper {
                .menu {
                    .login{
                        display: block;
                    }
                }
            } 
        }
    }
}