.ReactModal__Overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: scroll;
    @media (max-width: 1440px){
        .nav{
            padding: 31px 40px 0;
        }   
    }
    @include media-breakpoint-down(md) {
        z-index: 100 !important;
        margin-top: -8px;
    }
}
.modal-paywall {
    width: 50vw;
    position: relative;
    max-width: 865px;
    height: auto;
    max-height: 650px;
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.15);
    background-color: $white;
    z-index: 10;
    overflow: hidden;
    border: $black;
    @include media-breakpoint-down(xxl) {
        width: 80vw;
    }
    @include media-breakpoint-down(xl) {
        width: 100vw;
    }
    @include media-breakpoint-down(md) {
        width: 100%;
        height: 100vh;
        max-height: unset;
        flex-direction: column;
        overflow: scroll;
    }
    button.close {
        width: 30px;
        height: 30px;
        opacity: 1;
        position: relative;
        z-index: 100;
        margin: 30px;
        span {
            width: 30px;
            height: 2px;
            display: block;
            margin-bottom: 6px;
            z-index: 2;
            background-color: $gray-d2;
            position: absolute;
            &:nth-child(1) {
                transform: rotate(45deg);
            }
            &:nth-last-child(1) {
                transform: rotate(-45deg);
            }
            @include media-breakpoint-down(md) {
                background-color: $white;
            }
        }
    }
    .image-container {
        min-width: 290px;
        width: 40%;
        position: relative;
        @include media-breakpoint-down(md) {
            min-width: 100vw;
            max-height: 100vh;
            overflow: hidden;
            img {
                margin-top: -250px;
                width: 100%;
            }
        }
        svg {
            max-width: 164px;
            position: absolute;
            left: calc(50% - 82px);
            top: 80px;
            @include media-breakpoint-down(md) {
                left: calc(50% - 63px);
                top: 100px;
                max-width: 146px;
            }
            path {
                fill: $white;
            }
        }
        img {
            max-width: 100%;
        }
    }
    .text-container {
        padding: 25px 40px 100px 100px;
        @include media-breakpoint-down(md) {
            width: 100vw;
            padding: 40px;
            position: absolute;
            background-color: white;
            bottom: 0;
            h2 {
                margin-bottom: 10px !important;
            }
        }
        h2 {
            font-family: "Playfair Display";
            font-size: calc(24px + (40 - 24) * ((100vw - 300px) / (1920 - 300)));
            line-height: 48px !important;
            font-weight: 900;
            color: $gray-d2;
            margin-bottom: 20px;
        }
        ul {
            margin-bottom: 50px;
            @include media-breakpoint-down(md) {
                margin-bottom: 40px;
            }
            li {
                font-size: 20px;
                line-height: 50px;
                list-style: none;
                position: relative;
                @include media-breakpoint-down(md) {
                    line-height: 40px;
                    font-size: 18px;
                }
                svg {
                    position: absolute;
                    left: -33px;
                    top: 16px;
                }
            }
        }
        span {
            font-size: 20px;
            @include media-breakpoint-down(md) {
                font-size: 18px;
            }
            &.price {
                font-weight: 700;
            }
        }
        .button-container {
            text-align: center;
            .button-modal-subscription,
            .button-modal-login {
                margin-top: 40px;
                @include media-breakpoint-down(md) {
                    margin-top: 20px;
                }
            }
        }
    }
}
