.songbook-detail {
    .list {
        .song {
            transform: translateY(0px);
            &.active{
                z-index: 10;
            }
        }
    }
}
.songbook-head{
    border-top: 1px solid $gray-l1;
    margin-top: 40px !important;
    width: 100%;
    padding-left: 0 !important;
    div[class*="col-"]{
        padding: 0 !important;
    }
    i.spinner{
        position: absolute;
        right: 41%;
        top: 20px;
    }
    button{
        svg{
            margin: 0 5px 5px;
        }
    }
}
.song-detail{
    font-family: 'Nunito Sans', sans-serif;
    @include media-breakpoint-down(md){
        margin-top: 0;
        &>.container-fluid {
            flex-direction: column;
            display: flex;
        }
        .song-add-to-songbook-container {
            min-width: 42px;
            height: 42px;
            width: 42px;
            span.add{
                display: none;
            }
        }
    }
    a{
        color: $green;
        text-decoration: none;
        font-weight: 700;
    }
    .about-song-container {
        margin-bottom: 10px;
        // @include media-breakpoint-down(md){
        //     margin-top: 20px;
        // }
        @include media-breakpoint-down(sm){
            margin-top: 0;
        }
        .title-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include media-breakpoint-down(md){
                padding-right: 28px;
            }
            @include media-breakpoint-down(sm){
                padding-right: 17px;
            }
            .title{
                margin-bottom: 0;
                font-size: 34px;
                @include media-breakpoint-down(md){
                    font-size: 32px;
                }
                @include media-breakpoint-down(sm) {
                    font-size: 24px;
                    line-height: 32px;
                }
                span {
                    position: absolute;
                    font-size: small;
                    font-weight: normal;
                    top: -15px;
                    left: 19px;
                }
            }
            .like-container{
                @include media-breakpoint-down(md){
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: -50px;
                    .favourites{
                        position: relative;
                        z-index: 1;
                        margin-bottom: 10px;
                        right: unset;
                        display: flex;
                        justify-content: center;
                        transform: unset;
                        top: unset;
                        left: unset;
                        float: right;
                    }
                    .song-add-to-songbook{
                        @include media-breakpoint-down(md){
                            &.open{
                                .btn{
                                    svg.x-icon{
                                        top: 14px;
                                    }
                                }
                            }
                            &:not(.open){
                                .songbooks ul{
                                    max-height: unset !important;
                                    width: 0 !important;
                                }
                            }
                            .btn{
                                .svg-container{
                                    right: -2px;
                                    top: 2px;
                                }
                            }
                        }
                        @include media-breakpoint-down(sm) {
                            &.open{
                                .btn{
                                    svg.x-icon{
                                        top: 2px;
                                    }
                                }
                            }
                        }
                    }
                    .songbooks{
                        ul{
                            max-height: 100vh;
                            width: 90%;
                            padding-bottom: 200px;
                        }
                    }
                }
            }
        }
        .add-songs-container {
            @include media-breakpoint-down(sm) {
                order: 2;
            }
        }
        .subtitle-container {
            ul.artists{
                padding: 0;
                margin: 0;
                display: block;
                padding-bottom: 15px;
                li{
                    list-style: none;
                    margin-right: 20px;
                    a{
                        font-weight: 700;
                        color: $blue;
                    }
                }
            }
        }
        body.dark-mode & {
            color: $dark-mode-white;
        }
    }
    .subtitle-container{
        @include media-breakpoint-between(sm, md){
            padding-right: 6% !important;
        }
        ul.artists{
            @include media-breakpoint-down(xs){
                max-width: 70% !important;
            }
        }
    }
    .settings-bar-container {
        @include media-breakpoint-down(sm) {
            order: -1;
            margin-bottom: 32px;
        }
        .song-controls{
            .tones-sub{
                button{
                    color: $black !important;
                    &:hover{
                        color: $white !important;
                    }
                    &.active{
                        color: $white !important;
                    }
                }
            }
        }
        &.fixed {
            &>div {
                position: fixed;
                top: 105px;
                z-index: 2;
                min-width: auto;
                @include media-breakpoint-down(md){
                    top: 60px;
                }
                @include media-breakpoint-down(sm){
                    transition: all linear .2s;
                    padding: 0;
                    min-width: 100vw;
                    .settings-bar{
                        border-radius: 0 !important;
                    }
                }
            }
        }
        .svg-container{
            @include media-breakpoint-down(sm){
                left: calc(50% - 15px) !important;
            }
        }
    }
    .song-container {
        margin-top: 30px;
        &.playsongbook {
            margin-top: 104px;
        }
        @include media-breakpoint-down(sm) {
            margin-top: 0px;
            &.playsongbook{
                margin-top: 0;
            }
        }
    }
    canvas {
        body.dark-mode & {
            filter: invert(1);
        }
    }
    .top-text {
        font-weight: 700;
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .image-container {
        .overlay {
            opacity: 0;
            background-color: rgba(0,0,0, .5);
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            @media(max-width:1200px){
                opacity: 1 !important;
            }
            .thumb-text {
                font-size: 20px;
                color: white;
            }
        }
        &:hover {
            .overlay {
                opacity: 1;
                transition: opacity .2s linear;
            }
        }
    }
}

body.settings-bar-fixed{
    .chords-wrapper:not(.chord-cradle) .chord-container,
    .chords-wrapper:not(.chord-cradle) .chord-container {
        position: sticky;
        top: 230px;
        max-height: calc(100vh - 270px);
        overflow-y: scroll;
    }
}

@include media-breakpoint-up(sm) {
    .columns-2 {
        column-count: 2;
    }
}