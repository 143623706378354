div[id^='augl_leaderboard']{
    width: 100%;
    min-height: 90px;
    max-height: 100px;
    height: auto;
}

div[id^='augl_skyscraper_left']{
    width: 100%;
    min-height: 600px;
}

div[id^='augl_skyscraper_right']{
    width: 100%;
    height: 600px;
   }

div.adsense-bottom{
    margin-top: 50px;
}