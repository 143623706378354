
.generic-list {
    $self: &;
    padding-top: 40px;
    margin-bottom: 80px;

    display: flex;
    flex-wrap: wrap;
    margin-right: -17px;
    margin-left: -17px;

    h1 {
        font-family: "Playfair Display";	
        font-size: 3.6rem;	
        font-weight: 900;	
        line-height: 4.8rem;	
        text-shadow: 0 2px 5px 0 rgba(0,0,0,0.5);
        margin-bottom: 2rem;
    }
    a{
        color: $green;
        font-weight: 700;
    }
    

    &__artist {
        &__item {
            height: 200px;
    
            #{ $self }__title {
                margin-top: 1rem;
                margin-bottom: .5rem;
                a {
                    color: $black;
                    font-size: 2rem;
                    font-weight: 600;
                    line-height: 2.4rem;
                    text-decoration: none;
                }
            }
    
            #{ $self }__body {
                height: 145px;
                overflow: hidden;
            }
        }
    }

    &__item {
        height: 130px;
        margin: 0px;
        position: relative;

        border-top: 1px solid $gray-l4;
        body.dark-mode & {
            border-top: 1px solid $gray;
            &:first-child  {
                border-top: none;
            }
    
        }
        &:first-child  {
            border-top: none;
        }

        .checkbox {
            label {
                position: absolute;
                top: 50%;
                left: 20px;
                width: 20px;
                height: 20px;
                margin-bottom: 0px;
                border-radius: 2px;
                border: 1px solid #606060;
                transform: translateY(-50%);
                &:after{
                    height: 12px;
                    width: 6px;
                    display: block;
                    transform: rotate(45deg);
                    border-right: 2px solid $white;
                    border-bottom: 2px solid $white;
                    position: absolute;
                    left: 7px;
                    top: 2px;
                }
            }
            input[type="checkbox"]{
                position: absolute;
                display: none;
                &:checked + label {
                    background-color: $blue;
                    border: none;
                    &:after {
                        content: "";
                    }
                }
            }
        }
        .text {
            #{ $self }__title {
                margin-bottom: .5rem;
                font-weight: 600;
                font-size: 2rem;
                line-height: 2.4rem;
                text-decoration: none;
                a, .upsell-modal-opener {
                    color: $black;
                    body.dark-mode &{
                        color: $gray;
                    }
                }
                .upsell-modal-opener {
                    font-weight: 700;
                }
                .upsell-modal-opener:hover {
                    cursor: pointer;
                    text-decoration: underline;
                    text-decoration-color: #618B8F;
                }
            }
            #{ $self }__body {
                height: 45px;
                overflow: hidden;
                font-size: 1.6rem;
                body.dark-mode &{
                    color: $gray;
                }
            }
        }
        .favorite {
            position: absolute;
            right: 20px;
            top: 50%;
            display: flex;
            transform: translateY(-50%);
        }
    }

}
.lesson-list {
    .generic-list__item:first-child {
        border-top: 1px solid rgba(0, 0, 0, 0.075);
    }
}

.top-list-selector {
    font-size: 1.6rem;
    line-height: 2.2rem;
    text-transform: uppercase;
    color: $black;
    text-decoration: none;
    margin-right: 40px;
    @include media-breakpoint-down(sm){
        font-size: 1.4rem;
    }
    &:hover{
        color: $green-d1;
    }
    &:nth-last-child(1) {
        margin: 0px;
    }
    &.active{
        font-weight: 800;
        color: $green;
        border-bottom: 2px solid $green;
    }
}

