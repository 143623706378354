.search-container{
    @include media-breakpoint-down(md){
        body.searchresults & {
            height: 100vh;
            min-width: 100vw;
            position: fixed;
            top: 0 !important;
            left: 0 !important;
            padding: 0 !important;
            padding-top: 60px;
            transform: none !important;
        }
    }
    .search-wrapper{
        display: flex;
        background: $white;
        &.results{
            flex-direction: column;
            @include media-breakpoint-down(sm){
                height: 100%;
                .search-input{
                    .clear-search{
                        path{
                            fill: $gray-d3;
                        }
                    }
                }
                .search-result{
                    height: 100%;
                }
            }
            .search-input{
                padding: 12px !important;
                border-radius: 8px !important;
                border-bottom-right-radius: 0 !important;
                border-bottom-left-radius: 0 !important;
                box-shadow: none !important;
                body.dark-mode & {
                    background-color: $black-mode-bg;
                }
                .input-container{
                    background-color: $white-d3;
                    width: 100%;
                    color: $gray-d2 !important;
                    border-radius: 4px;
                    position: relative;
                    padding-left: 18px;
                    box-shadow: 0 1px 0 0 rgba(0,0,0,0.15);
                    body.dark-mode & {
                        background-color: $black-mode-bg-nav;
                    }
                    svg{
                        @include media-breakpoint-down(sm){
                            max-height: 19px;
                        }
                    }
                    input{
                        color: $gray-d2;
                        padding-left: 10px !important;
                        body.dark-mode & {
                            background-color: $black-mode-bg-nav;
                            color: $white;
                        }
                    }
                }
                .clear-search{
                    cursor: pointer;
                }
            }
            .search-results{
                body.dark-mode & {
                    background-color: $black-mode-bg;
                }
            }
            .toplists{
                display: none;
            }
        }
        .search-input {
            body.dark-mode & {
                background-color: $black-mode-bg-nav !important;
                input{
                    color: $white;
                    &::placeholder{
                        color: $white;
                    }
                }
            }
            .input-container{
                width: 100%;
                display: flex;
                align-items: center;
                svg {
                    path{
                        fill: $white;
                    }
                }
            }
        }
    }
    .search{  
        height: 100%;
        .search-input {
            position: relative;
            padding-left: 30px;
            width: 100%;
            display: flex;
            align-items: center;
            @include media-breakpoint-down(md){
                padding-left: 23px;
                padding-right: 8px;
                input{
                    padding-left: 12px;
                }
                svg{
                    margin-right: 0 !important;
                }
            }
            input {
                width: 100%;
                height: 6rem;
                border: none;
                color: $black;
                font-size: 20px;
                font-weight: 600;
                line-height: 50px;
                padding-top: 0;
                padding-bottom: 0;
                outline: none;
                background-color: transparent;
                &::placeholder{
                    color: $black;
                    opacity: 1;
                }
                @include media-breakpoint-down(sm){
                    margin-left: 8px;
                    font-size: 18px;
                    &::placeholder{
                        font-size: 18px;
                    }
                }
            }
            svg {
                margin-right: 8px;
                path {
                    fill: $white;
                } 
            }
            .clear-search{
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                cursor: pointer;
                svg{
                    path{
                        fill: $red;
                    }
                }
            }
        }
        .search-results{
            overflow-y:  scroll;
            overflow-x: hidden;
            margin-top: 0;
            background: $white;
            border-radius: 8px;
            border-top-right-radius: 0px;
            border-top-left-radius: 0px;
            .favourites-list {
                @include media-breakpoint-down(md){
                    padding: 20px;
                    .filter-info{
                        padding: 0 14px 8px 14px;
                    }
                }
                .song-add-to-songbook-container{   
                    + .song-list{
                        margin-top: 20px;
                    }
                }
            }
            @include media-breakpoint-down(sm){
                padding-top: 10px;
            }
            body.nav-fixed.dark-mode & {
                background: $black-mode-bg-nav;
            }
            &.Author {
                height: 50px;
            }
            &.Song {
                height: 112px;
            }
            .new-songbook {
                position: relative;
                border-top: 1px solid $gray;
                margin-bottom: 50px;
                .create-new-songbook{
                    // position: absolute;
                    // bottom: 20px;
                    left: 0;
                    width: 100%;
                    height: 134px;
                    max-width: 250px;
                }
            }
            .song-add-to-songbook-container{
                position: absolute;
                top: 82px;
                opacity: 1;
                &[disabled] {
                    opacity: 0;
                    .song-add-to-songbook {
                        background-color: lighten($green, 10%);
                        .btn-icon-after {
                            border-color: lighten($green, 10%);
                        }
                    }                    
                }
                .song-add-to-songbook{
                    z-index: 1;
                    width: 95%;
                    margin: 0 auto;
                    .btn{
                        @include media-breakpoint-down(md){
                            width: 100%;
                        }
                    }
                    &.open{
                        width: 100%;
                        height: 400px;
                        @include media-breakpoint-down(md){
                            height: 80vh;
                        }
                        button.btn{
                            svg{
                                left: unset;
                                right: 0;
                                position: absolute;
                                top: 26%;
                            }
                        }
                        .songbooks{
                            width: 100%;
                            height: 100%;
                            ul{
                                width: 100%;
                                background-color: $white;
                                min-height: 340px;
                                @include media-breakpoint-down(md){
                                    min-height: 90vh;
                                }
                                li{
                                    height: 55px;
                                    align-items: center;
                                }
                            }
                            .add-new-open{
                                bottom: 200px !important;
                            }
                            .new-songbook{
                                background-color: $white;
                                height: 50px;
                                margin-bottom: 0;
                                border-top: 0;
                            }
                        }
                    }
                }
            }
            .song-list.favourites-list{
                max-height: 440px;
                @include media-breakpoint-down(md){
                    height: 100%;
                    max-height: 80vh;
                }
            }
            .song-list{
                @include media-breakpoint-down(sm){
                    &:first-of-type{
                        .item{
                            border-top: none !important;
                        }
                    }
                    .item{
                        margin: 0 auto;
                        padding: 10px 40px !important;
                        max-height: 104px;
                        overflow: hidden;
                        width: 100% !important;
                        label{
                            right: unset;
                            left: 14px !important;
                            width: 15px;
                            height: 15px;
                            top: 15px;
                            &::after{
                                left: 5px !important;
                                top: 0px !important;
                            }
                        }
                    }
                    .actions {
                        right: 18px !important;
                        top: 13px !important;
                    }
                    .song-excerpt{
                        height: 49px;
                    }
                }
            }
            .search-result{
                padding: 1.5rem 15.5rem 1.5rem 4.5rem;
                position: relative;
                border-top: 1px solid $gray-l1;
                position: relative;
                .actions {
                    position: absolute;
                    right: 20px;
                    top: 20px;
                    display: flex;
                    a {
                        svg {
                            path {
                                stroke: $gray-d2;
                            }
                        }
                    }
                }
                label {
                    position: absolute;
                    left: 16px;
                    top: 20px;
                    height: 15px;
                    width: 15px;
                    margin-bottom: 0px;
                    border-radius: 2px;
                    border: 1px solid $gray-d1;
                    &:after{
                        height: 10px;
                        width: 5px;
                        display: block;
                        transform: rotate(45deg);
                        border-right: 2px solid $white;
                        border-bottom: 2px solid $white;
                        position: absolute;
                        left: 5px;
                        top: 1px;
                    }
                }
                input[type="checkbox"]{
                    position: absolute;
                    display: none;
                    &:checked + label {
                        background-color: $blue;
                        border: none;
                        &:after {
                            content: "";
                        }
                    }
                }
                input[type="checkbox"]:checked + label {
                    background-color: $blue;
                    border: none;
                }
                input[type="checkbox"]:checked + label::after {
                    content: "";
                }
                a{
                    text-decoration: none;
                }
                .title{
                    color: $black;
                    font-weight: 400;
                    font-size: 1.5rem;
                    white-space: nowrap;
                    span{
                        color: $blue;
                    }
                }
                .song-excerpt{
                    font-weight: 300;
                    font-size: 1.5rem;
                    color: $gray-d1;
                    overflow: hidden;
                    width: 100%;
                    height: 60px;
                    line-height: 1.3;
                    span{
                        color: $green;
                    }
                }
            }
        }
    }
    .toplists{
        margin-top: 23px;
        justify-content: center;
        @include media-breakpoint-down(xs) {
            margin-top: 12px;
        }
        li{
            margin-left: 10px;
            margin-right: 10px;
            @include media-breakpoint-down(md){
                margin-top: 10px;
            }
            &:nth-child(1) {
                margin-left: 0px;
            }
            &:nth-last-child(1) {
                margin-right: 0px;
            }
            a{
                font-weight: 500;
                font-size: 1.4rem;
                color: $white;
                background-color: $black;
                opacity: .9;
                height: 26px;
                line-height: 26px;
                padding: 0 15px;
                border-radius: 13px;
                transition: opacity .3s linear;
                &:hover{
                    opacity: 1;
                }
                @include media-breakpoint-down(xs) {
                    font-size: 1.3rem;
                }
            }
        }
    }
}
body.searchresults{
    @include media-breakpoint-down(md){
        .menu-wrapper{
            z-index: -1;
        }
    }
}
body:not(.searchresults){
    &:not(.page-homepage){
        .search{
            .search-input{
                .input-container{
                    background-color: transparent !important;
                    height: 25px;
                    box-shadow: none !important;
                    input{
                        color: $white !important;
                    }
                }
                .clear-search{
                    svg{
                        path{
                            fill: $white !important;
                        }
                    }
                }
            }
        }
    }
}
body.searchresults:not(.page-homepage) {
    &.dark-mode{
        .search-container{
            background-color: $black-mode-bg-nav;
            padding-top: 0;
            .search-results{
                background-color: $black-mode-bg;
            }
        }
    }
    .song-add-to-songbook-container{
        padding: 0 12px;
    }
    .search-container{
        top: 17px;
        padding: 10px;
        border-radius: 8px;
        background-color: $white;
        box-shadow: 0 20px 60px 0 rgba(0,0,0,0.15);
        .search{  
            .search-wrapper {
                height: auto!important;
                top: 10px;
                background-color: transparent!important;
                border-radius: 0px!important;
                .search-input {
                    border-radius: 4px;
                    svg {
                        top: 14px !important;
                       path {
                        fill: $red;
                       } 
                    }
                }
            }  
        }
    }
}

body.page-homepage{
    &.searchresults{
        .song-add-to-songbook-container{
            padding: 0 12px;
            &.songbook-open{
                @include media-breakpoint-down(md){
                    height: 100vh;
                    .new-songbook:not(.add-new-open){
                        position: fixed;
                        width: 100%;
                        bottom: 0;
                        height: 60px !important;
                        margin-bottom: 0 !important;
                    }
                }
            }
            .song-add-to-songbook{
                &.open{
                    margin-top: 12px;
                    height: auto !important;
                    @include media-breakpoint-down(md){
                        height: 100% !important;
                        margin-top: 0;
                    }
                    .songbooks{
                        height: calc(70vh - 352px) !important;
                        @include media-breakpoint-down(md){
                            height: 100vh !important;
                        }
                        ul{
                            min-height: unset !important;
                            @include media-breakpoint-down(md){
                                li.new-book{
                                    .new-songbook{
                                        bottom: 0px !important;
                                        position: relative;
                                        max-width: unset;
                                        width: unset;
                                        margin-bottom: -10px !important;
                                    }
                                }
                            }
                        }
                    }
                    .create-new-songbook{
                        margin-top: 32px;
                    }
                }
            }
        }
        .search-results{
            @include media-breakpoint-up(md){
                max-height: calc(70vh - 300px);
            }
            @include media-breakpoint-down(md){
                .song-list.favourites-list{
                    max-height: 90vh;
                }
            }
        }
    }
    .search-container{
        .search{    
            .search-input {
                .clear-search{
                    svg{
                        path{
                            fill: $red !important;
                        }
                    }
                }
                .input-container{
                    svg {
                        @include media-breakpoint-down(sm){
                            top: 20px; 
                        }
                        path {
                            fill: $red !important;
                        } 
                    }
                }
            }
        }
    }
}