.ReactModal__Overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1440px){
        .nav{
            padding: 31px 40px 0;
        }   
    }
    @include media-breakpoint-down(md) {
        z-index: 100 !important;
        margin-top: -8px;
    }
}
body.dark-mode {
   .modal-delete-account {
        background-color: $gray-d2;
        .text-container {
            button.close span {
                background-color: $white;
            }
        }

   }
}
.modal-delete-account {
    border-radius: 8px;
    overflow: scroll;
    background-color: $white;
    width: 50vw;
    position: relative;
    max-width: 550px;
    height: auto;
    max-height: 650px;
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.15);
    z-index: 10;
    border: $black;
    @include media-breakpoint-down(xxl) {
        width: 80vw;
    }
    @include media-breakpoint-down(xl) {
        width: 100vw;
    }
    @include media-breakpoint-down(md) {
        width: 100%;
        height: 100%;
        max-height: unset;
        flex-direction: column;
    }
    .text-container {
        button.close {
            opacity: 1;
            z-index: 100;
            top: 20px;
            position: absolute;
            width: 26px;
            height: 26px;
            right: 20px;
            span {
                width: 30px;
                height: 2px;
                display: block;
                margin-bottom: 6px;
                z-index: 2;
                background-color: $gray-d2;
                position: absolute;
                &:nth-child(1) {
                    transform: rotate(45deg);
                }
                &:nth-last-child(1) {
                    transform: rotate(-45deg);
                }
                @include media-breakpoint-down(md) {
                    background-color: $white;
                }
            }
        }
        padding: 40px;
        a {
            font-size: 20px;
            line-height: 50px;
            color: $gray-d2;
            @include media-breakpoint-down(md) {
                font-size: 18px;
            }
            &:hover {
                text-decoration: none;
                font-weight: 600;
            }
        }
        #usernameInput{
            margin: 20px 0;
            height: 42px;
        }
        .delete-btn{
            border-radius: 4px;
            background: $gray;
            font-size: 16px;
            font-weight: 600;
            color: $white;
            border: none;
            width: 100%;
            padding: 11px;
            &:hover{
                transition: background-color .2s linear;
                background-color: $error;
                cursor: pointer;
            }
        }
    }
    .undo-container{
        button{
            width: 50%;
            background-color: $blue;
            height: 42px;
            margin-top: 20px;
            font-size: 14px;
            font-weight: 600;
            body.dark-mode &{
                background-color: $red;
                &:hover{
                    background-color: darken($red, 10%);
                }
            }
        }
    }
}