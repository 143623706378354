.standard-page, .paragraph-block{
    h1, h2, h3, h4{
        font-family: 'Playfair Display', serif;
        margin: 40px 0 10px;
    }
    h1{
        font-size: 3rem;
        @include media-breakpoint-down(sm){
            font-size: 2.8rem;
            line-height: 30px;
        }
    }
    h2{
        font-size: 2.2rem;
        @include media-breakpoint-down(sm){
            font-size: 2rem;
            line-height: 22px;
        }
    }
    h3{
        font-weight: 900;
        font-size: 2rem;
        @include media-breakpoint-down(sm){
            font-size: 1.8rem;
            line-height: 20px;
        }
    }
    h4{
        @include media-breakpoint-down(sm){
            line-height: 20px;
        }
    }
    a{
        text-decoration: none;
        color: $green-d1;
        text-decoration: underline;
    }
    body.dark-mode & {
        h2, h3{
            color: $white;
        }
    }
}