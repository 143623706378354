.teaching-block-container {
    position: relative;
    overflow-x: hidden;
    padding: 80px 0px;
    @include media-breakpoint-down(md) {
        overflow-y: hidden;
        padding-bottom: 0px;
    }
    &:before {
        content: "";
        position: absolute;
        top: 50%;
        right: 0px;
        width: calc(100% - 250px);
        height: calc(100% - 160px);
        background-color: $white;
        transform: skew(-20deg) translateY(-50%);
        transform-origin: bottom right;
        z-index: -1;
        box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.15);
        @include media-breakpoint-down(md) {
            top: 400px;
            width: 100%;
            transform: skewY(-10deg) translateY(0%);
        }
        @include media-breakpoint-down(sm) {
            top: 300px;
        }
        @include media-breakpoint-down(xs) {
            top: 280px;
        }
    }
    .teaching-block {
        position: relative;
        padding: 120px 0px 120px 80px;
        @include media-breakpoint-down(lg) {
            padding-left: 40px;
        }
        @include media-breakpoint-down(md) {
            padding-left: 0px;
            padding-bottom: 80px;
            padding-top: 80px;
            &>.row{
                flex-direction: column;
            }
        }
        @include media-breakpoint-down(sm) {
            padding-top: 60px;
            padding-bottom: 60px;
        }
        &:after {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0px;
            width: 100%;
            height: calc(100% - 80px);
            background-color: $green;
            z-index: -2;
            @include media-breakpoint-down(md) {
                height: 100%;
                width: calc(100% - 20px);
                left: 10px;
            }
        }
        .img-container {
            position: relative;
            height: auto;
            align-items: center;
            display: flex;
            &>img {
                margin: 0 auto;
                display: block;
                max-width: 100%;
                @include media-breakpoint-down(md) {
                    max-width: 80%;
                }
                @include media-breakpoint-down(xs) {
                    max-width: calc(100% - 40px);
                }
            }
            .img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -56%);
                width: 450px;
                height: 290px;
                overflow: hidden;
                @include media-breakpoint-down(lg) {
                    height: 253px;
                    width: 388px;
                }
                @include media-breakpoint-down(md) {
                    height: 280px;
                    width: 427px;
                }
                @include media-breakpoint-down(sm) {
                    height: 207px;
                    width: 320px;
                }
                @include media-breakpoint-down(xs) {
                    height: 79%;
                    width: 68vw;
                }
                img {
                    width: 100%;
                }
            }
        }
        .text-container {
            @include media-breakpoint-down(md) {
                margin-top: 120px;
            }
            @include media-breakpoint-down(sm) {
                max-width: calc(100% - 80px);
                margin: 80px auto 0px;
            }
            @include media-breakpoint-down(xs) {
                margin-top: 60px;
            }
            span {
                width: 50px;
                height: 2px;
                background-color: $red;
                display: block;
            }
            h2 {
                font-family: 'Playfair Display', serif;
                line-height: 1;
                padding-top: 50px;
                margin-bottom: 10px;
                font-size: 6rem;
                color: $black;
                position: relative;
                @include media-breakpoint-down(sm) {
                    padding-top: 24px;
                    font-size: 4.2rem;
                }
            }
            h3 {
                font-family: 'Playfair Display', serif;
                font-weight: 900;
                font-size: 4rem;
                line-height: 1;
                color: $green;
                @include media-breakpoint-down(sm) {
                    font-size: 2.5rem;
                }
            }
            .rich-text {
                font-size: 18px;
                line-height: 30px;
                margin-top: 24px;
                @include media-breakpoint-down(sm) {
                    font-size: 16px;
                }
            }
            a {
                margin-top: 48px;
                width: 180px;
                height: 55px;
                border-radius: 28px;
                background-color: $gray-l2;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $green;
                text-transform: uppercase;
                font-size: 14px;
                line-height: 21px;
                font-weight: 600;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}