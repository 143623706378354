@import "custom";
@import "~bootstrap/scss/bootstrap";
@import "fontawesome/font-awesome";

@import "sass-space";
@import "colors";
@import "base";
@import "rangeslider";
@import "nav";
@import "footer";
@import "chordsearch.scss";
@import "search.scss";
@import "alphabet.scss";
@import "spinner.scss";
@import "generic-list.scss";
@import "paginator.scss";
@import "menu.scss";
@import "progress-bar.scss";
@import "step-indicator.scss";
@import "ads";
@import "songbook-create";
@import "tags";

/* Author Detail Page */
@import "author";

/* Song Detail Page */
@import "song";
@import "song-autoscroll";
@import "song-chords";
@import "song-chord-colors";
@import "song-font-size";
@import "song-favourites";
@import "song-show-chords";
@import "song-view-mode";
@import "song-add-to-songbook";
@import "song-transposer";
@import "song-share-songbook";
@import "song-controls";
@import "song-tab";
@import "song-chord-cradle-toggler";

/* Song list page */
@import "song-list";
@import "song-list-component";

/* Songbook */
@import "songbooks";
@import "songbook-pdf-modal";
@import "homepage";

/* Submit song view */
@import "song-chordified";
@import "submit-a-song";

/* Lesson Detail Page */
@import "lessons";

/* Profile */
@import "profile";

/* Subscription */
@import "subscription";

/* Wishlist */
@import "wishlist";

/* Countdown timer */
@import "countdown-timer";

/* Related songs */
@import "related-songs";

/* Blocks */
@import "blocks/text_pictures_block";
@import "blocks/cards_block";
@import "blocks/author_block.scss";
@import "blocks/teaching_block";
@import "blocks/paragraph_block";

/* modals */
@import "modal-login";
@import "modal-fullscreen";
@import "modal-subscription";
@import "modal-paywall";
@import "modal-payment-info";
@import "modal-usersongs";
@import "modal-delete-account";
@import "modal-receipt";
@import "modal-chordchooser";
@import "modal-add-email";
@import "modal-qr";

.show-print {
    display: none;
}