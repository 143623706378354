.chord-cradle-toggler {
    position: absolute;
    top: -10px;
    right: 0;
    //border-top: 1px solid $gray-d3;
    cursor: pointer;
    svg {
        height: 16px;
        width: 16px;
        transform: rotate(90deg);
        //position: absolute;
        //right: 0;
    }
    .chord-cradle-show & {
        transform: rotate(180deg);
    }
    .chord-cradle-hide & {
        top: -15px;
        text-align: right;
        width: 100%;
    }
    body.dark-mode & {
        svg {
            fill: $gray;
        }
    }
}

.chord-cradle-hide {
    height: 22px;
}