.menu-container{
    display: none;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    background: $green;
    body.dark-mode & {
        background: $green-dark-mode; 
    }
    &.user-authenticated {
        background: $red;
        overflow: scroll;
        body.dark-mode & {
            background: $black-mode-bg;
        }
        button.modal-opener.button-modal-subscription {
            background-color: $white;
            color: $gray-d2;
        }
    }
    .menu-wrapper{
        height: 100vh;
        padding-top: 120px;
        padding-bottom: 100px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        ul{
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: 0px;
            width: fit-content;
            width: -moz-fit-content;
            margin: 0 auto;
            &.subscription-btn-visible{
                padding-top: 45px;
                position: relative;
            }
            li{
                color: $white;
                font-family: "Nunito Sans";
                font-size: 2.8rem;
                font-weight: 300;
                letter-spacing: 0;
                line-height: 6.8rem;
                list-style: none;
                position: relative;
                margin-left: 40px;
                &:hover{
                    a, button{
                        border-bottom: 2px solid $white;
                        transition: border-bottom linear .4s;
                        svg{
                            animation: shake 0.82s ease-in-out 0.1s infinite both;
                        }
                    }
                }
                body.dark-mode & {
                    &:hover{
                        a, button{
                            border-bottom: 2px solid $red;
                        }
                    }
                }
                @keyframes shake {
                    0% { transform: rotate(0); }
                    15% { transform: rotate(5deg);}
                    30% { transform: rotate(-5deg);}
                    45% { transform: rotate(4deg);}
                    60% { transform: rotate(-4deg);}
                    75% { transform: rotate(2deg);}
                    85% { transform: rotate(-2deg);}
                    92% { transform: rotate(1deg);}
                    100% { transform: rotate(0);}
                }
                &.menu__buy {
                    margin-left: 0px;
                    position: absolute;
                    top: -35px;
                    z-index: 10;
                    @include media-breakpoint-down(sm){
                        margin-bottom: 40px;
                    }
                    button{
                        width: 268px;
                        margin-left: -20px;
                    }
                }
                &.log-out{
                    margin-bottom: 20px;
                    margin-left: 40px;
                    button{
                        padding-left: 0;
                        font-size: 2.8rem;
                    }

                }
                a, button{
                    color: $white;
                    text-decoration: none;
                    border-bottom: 2px solid transparent;
                }
                svg{
                    height: 24px;
                    position: absolute;
                    top: 36%;
                    right: 108%;
                    &.heart{
                        height: 20px;
                    }
                    &>g {
                        stroke: $white;
                    }
                    &>path {
                        fill: $white;
                    }
                }
            }
        }
    }
}
body.menu-open{
    overflow: hidden;
    position: fixed;
    .navbar-brand{
        filter: invert(1);
    }
    #search-container{
        display: none;
    }
    #menu .login{
        color: $white;
        a {
            color: $white;
        }
    }
    .menu-container{
        display: block;
    }
}


@include media-breakpoint-down(lg){
    .menu-container{
        .menu-wrapper{
            height: auto;
            min-height: 100vh;
            padding-bottom: 180px;
        }
        .logout-container{
            background-color: $black-mode-bg;
            bottom: 0;
            body.dark-mode & {
                background-color: $red;
            }
            .log-out{
                list-style: none;
                position: relative;
                margin-left: 40px;
                width: 195px;
                &:hover{
                    button{
                        svg{
                            animation: shake 0.82s ease-in-out 0.1s infinite both;
                        }
                    }
                }
                button{
                    color: $white;
                    font-family: "Nunito Sans";
                    font-size: 2.8rem;
                    font-weight: 300;
                    letter-spacing: 0;
                    line-height: 5rem;
                    padding-left: 0;
                    font-size: 2.8rem;
                    svg{
                        height: 24px;
                        position: absolute;
                        top: 36%;
                        right: 105%;
                        &>g {
                            stroke: $white;
                        }
                        &>path {
                            fill: $white;
                        }
                    }
                }
            }
        }
    }
}
