.text-pictures-block{
    min-height: 100vh;
    padding-top: 11.6rem;
    position: relative;
    overflow: hidden;
    @include media-breakpoint-down(md) {
        padding-bottom: 80px;
    }
    body.dark-mode & {
        &::before{
            background-color: $black-mode-bg;
        }
    }
    &:before{
        content: '';
        position: absolute;
        height: 60px;
        bottom: 0;
        left: 0;
        right: 0;
        transform: rotate(2deg);
        transform-origin: bottom left;
        background-color: $white-d1;
        box-shadow: 0 -10px 40px 0 rgba(0,0,0,0.05);
    }
    &:after{
        content: '';
        width: 53%;
        background-color: $gray-l2;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: -1;
        transform: skew(-31deg);
        transform-origin: bottom right;
        @include media-breakpoint-down(md) {
            width: 80%;
        }
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }
    .text-container {
        h2{
            font-family: 'Playfair Display', serif;
            line-height: 1;
            padding-top: 50px;
            margin-bottom: 10px;
            font-size: 6rem;
            color: $black;
            position: relative;
            &:before{
                content: '';
                border-top: 2px solid $red;
                width: 50px;
                position: absolute;
                top: 0;
                left: 0;
                @include media-breakpoint-down(sm) {
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
            @include media-breakpoint-down(sm){
                font-size: 4.2rem;
                padding-top: 20px;
            }
        }
        h3{
            font-family: 'Playfair Display', serif;
            font-weight: 900;
            font-size: 4rem;
            line-height: 1;
            color: $green;
            @include media-breakpoint-down(sm){
                font-size: 2.5rem;
            }
        }
        .rich-text{
            font-size: 1.8rem;
            line-height: 3rem;
            color: $gray-d2;
    
            p{
                margin-top: 3rem;
            }
        }
    }
    // .thumbnails{
    //     display: flex;
    //     justify-content: space-between;
    // }
    .img-container {
        &:nth-last-child(1), &:nth-last-child(2) {
            @include media-breakpoint-down(sm) {
                margin-top: 0px;
            }
        }
        @include media-breakpoint-down(md) {
            margin-top: 40px;
        }
        @include media-breakpoint-down(sm) {
            margin-top: 20px;
        }
        @include media-breakpoint-down(xs) {
            justify-content: space-between;
            display: flex;
        }
        @media (max-width: 370px) {
            flex-direction: column;
        }
        .thumb{
            max-width: 100%;
            height: auto;
            border: 10px solid $white;
            box-shadow: 0 10px 30px 0 rgba(0,0,0,0.10);
            margin-bottom: 30px;
            @include media-breakpoint-down(xs) {
                width: calc(50% - 10px);
            }
            @media (max-width: 370px) {
                width: 100%;
                max-width: unset;
            }
            &.v-1{
                margin-top: 90px;
                @include media-breakpoint-down(xs) {
                    margin-top: 0px;
                }
            }
            &.v-2{
                margin-top: 210px;
                @include media-breakpoint-down(xs) {
                    margin-top: 0px;
                }
            }
        }
    }
}