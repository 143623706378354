.ReactModal__Content {
    border-radius: 0;
    @include media-breakpoint-down(md){
        height: 100% !important;
    }
    &.modal-login, &.modal-subscription {
        width: 100vw!important;
        min-height: 100vh;
        background-color: $gray-d2;
        transform: translate(0, 0)!important;
        top: 0px!important;
        left: 0px!important;
        z-index: 10;
        .modal-footer {
            border: none;
            max-width: 620px;
            margin: 0 auto;
            padding-top: 44px;
            width: calc(100% - 40px);
            display: flex;
            flex-direction: column;
            .btn {
                height: 55px;
                border-radius: 30px;
                font-size: 14px;
                line-height: 19px;
                color: $white;
                &:focus {
                    outline: none;
                    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
                }
                &:nth-child(1) {
                    background-color: $red;
                    box-shadow: 0 0 25px 0 rgba(0,0,0,0.25);
                    padding: 0px 100px;
                    font-weight: 800;
                    text-transform: uppercase;
                }
            }
            .fb-login{
                margin: 40px auto;
                display: flex;
                justify-self: center;
                align-items: center;
                height: 37px;
                background-color: $fb-blue;
                border-radius: 3.2px;
                padding: 10px 14px;
                text-transform: none;
                @include media-breakpoint-down(sm){
                    margin: 26px auto;
                }
                svg{
                    width: 19px;
                    margin-right: 8px;
                }
                a{
                    color: $white;
                    font-weight: 600;
                }
            }
            .forgot-pass{
                font-size: 16px;
            }
        }
    }
}