.payment-info-modal{
    max-width: unset !important;
    width: 100% !important;
    max-height: 100vh;
    overflow: hidden;
    overflow-y: scroll;
    .logo-container{
        width: 140px !important;
        @media (max-width: 1440px){
            width: 100px !important;
        }
    }
    .modal-title{
        margin-top: 0 !important;
    }
    &.payment-done {
        p {
            color: $white;
        }
    }
    .subscription-description{
        line-height: 59px;
        padding-left: 17px;
        padding-right: 17px;
        .borders{
            border-top: 1px solid $white-50;
            border-bottom: 1px solid $white-50;
            display: flex;
            justify-content: space-between;
            @include media-breakpoint-down(md){
                font-size: 16px;
                span{
                    font-size: 12px !important;
                    padding-left: 8px;
                    line-height: 20px;
                }
            }
            @include media-breakpoint-down(sm){
                font-size: 14px;
                line-height: 17px;
                padding: 6px 0;
            }
        }
        .title{
            color: $white;
            font-size: 24px;
            font-weight: 200;
            @include media-breakpoint-down(lg){
                font-size: 20px;
                line-height: 28px;
            }
            @include media-breakpoint-down(sm){
                .title-inner{
                    max-width: 66%;
                    padding-right: 50px;
                }
            }
        }
        .text-right{
            font-size: 24px;
            line-height: 57px;
            @include media-breakpoint-down(lg){
                font-size: 20px;
                line-height: 56px;
            }
            @include media-breakpoint-down(sm){
                line-height: 28px;
            }
            span{
                font-size: 18px;
                font-weight: 200;
            }
        }
        .dropdown{
            @include media-breakpoint-down(sm){
                margin-top: 6px;
            }
        }
    }
    form.login{
        @include media-breakpoint-down(sm){
            padding-bottom: 80px;
        }
    }
    .cardholder{
        margin: 60px 0;
        @media (max-width: 1440px){
            margin: 30px 0 0;
            input{
                height: 42px !important;
            }
        }
        input{
            height: 59px;
        }
    }
    .cardholder, .payment-info{
        input{
            background-color: transparent;
            border-left: none;
            border-right: none;
            border-top: none;
            border-radius: 0;
            font-size: 24px;
            color: $white;
            padding-left: 0;
            &:focus{
                outline: none !important;
                border-color: transparent;
                box-shadow: none;
            }
            &::placeholder{
                font-size: 18px;
            }
        }
        label{
            color: $white;
            font-size: 13px;
        }
    }
    .payment-info{
        input{
            height: 46px;
            border-bottom: 1px solid $white;
        }
        label{
            margin-bottom: 0;
        }
        .cardnr-col{
            input{
                width: 100%;
            }
            svg{
                position: absolute;
            }
            .error-msg{
                position: absolute;
                color: $error;
                bottom: -30px;
                left: 16px;
            }
        }
    }
    .pay{
        margin: 60px auto;
        height: 65px;
        width: 100%;
        background-color: $red;
        border-radius: 50px;
        font-size: 16px;
        font-weight: 800;
        border-color: transparent;
        @media (max-width: 1440px){
            margin: 15px auto;
        }
        &:focus{
            border-color: transparent;
            outline: none !important;
            box-shadow: none !important;
        }
        &:active{
            background-color: $green !important;
            border-color: $green !important;
            outline: none !important;
        }
    }
    .subscription-iframe-container {
        iframe {
            width: 100%;
            height: 475px;
            border: 0;
            background: white;
        }
    }
}