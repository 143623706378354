.modal-login {
    .login{
        background-color: $gray-d2;
    }
    &.signup-container{
        .button-row{
            button{
                h5.sign-up{
                    border-bottom: 2px solid $red;
                }
            }
        }
    }
    &.login-container{
        .button-row{
            button{
                h5.sign-in{
                    border-bottom: 2px solid $red;
                }
            }
        }
    }
    &.forgot-pass-container{
        .button-row{
            button{
                h5.forgot-pass{
                    border-bottom: 2px solid $red;
                }
            }
        }
    }
    .nav {
        justify-content: space-between;
        padding: 31px 40px;
        z-index: 1;
        position: relative;
        @include media-breakpoint-down(sm) {
            padding: 31px 25px;
        }
        &.signup, &.login{
            justify-content: space-between;
        }
        &.forgot-pass{
            top: 48px;
        }
        .lang {
            font-size: 1.9rem;
            line-height: 2.6rem;
            color: $white;
            cursor: pointer;
        }
        button.close {
            width: 30px;
            height: 30px;
            opacity: 1;
            transform: translateY(-3px);
            span {
                width: 30px;
                height: 2px;
                display: block;
                margin-bottom: 6px;
                z-index: 2;
                background-color: $white;
                position: absolute;
                &:nth-child(1) {
                    transform: rotate(45deg);
                }
                &:nth-last-child(1) {
                    transform: rotate(-45deg);
                }
            }
        }
    }
    form{
        &.signup{
            .modal-body{
                .logo-container{
                    svg{
                        padding-top: 170px !important;
                    }
                }
                .button-row{
                    @include media-breakpoint-down(sm){
                        margin-bottom: -50px !important;
                    }
                }
                .form-group{
                    @include media-breakpoint-down(sm){
                        padding-top: 20px;
                        input{
                            z-index: 10;
                            position: relative;
                        }
                    }
                }
            }
        }
        &.forgot-pass{
            .modal-body{
                .logo-container{
                    svg{
                        padding-top: 0 !important;
                        margin-top: -120px;
                    }
                }
            }
        }
    }
    .login {
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
        &.pass-complete{
            h5{
                text-align: center;
                margin-top: 70px;
            }
        }
        &.signup-complete{
            h5{
                text-align: center;
                margin-top: 70px;
            }
        }
        .modal-body {
            border: none;
            max-width: 620px;
            margin: 0 auto;
            width: calc(100% - 40px);
            p{
                text-align: center;
                color: $white;

            }
            .logo-container {
                width: 200px;
                margin: 0 auto;
                display: block;
                @include media-breakpoint-down(sm){
                    display: none;
                }
                .logo {
                    fill: white;
                    @include media-breakpoint-down(sm){
                        width: 120px;
                        padding-top: 40px;
                    }
                }
            }
            .title-row{
                margin-top: 100px;
                margin-bottom: 60px;            
            }
            .button-row{
                margin-bottom: 60px !important;
                justify-content: space-between;
                margin-top: 100px;
                @include media-breakpoint-down(sm){
                    margin-bottom: 0 !important;
                }
                .btn{
                    padding: 0;
                    &:focus{
                        box-shadow: none;
                    }
                }
            }
            .modal-title, .sign-up {
                font-size: 15px;
                font-weight: 800;
                line-height: 20px;
                color: $white;
                text-transform: uppercase;
            }
            .form-group {
                position: relative;
                padding-top: 36px;
                &:nth-child(3) {
                    margin-top: 44px;
                }
                p {
                    font-size: 18px;
                }

                &.terms{
                    input{
                        width: 20px;
                    }
                    label{
                        font-size: 14px;
                        padding-left: 30px;
                        margin-bottom: 1px;
                        a{
                            font-size: 14px;
                        }
                    }
                }
                label {
                    position: absolute;
                    font-size: 24px;
                    font-weight: 200;
                    line-height: 38px;
                    bottom: 0;
                    color: $white;
                    &:not(.events) {
                        pointer-events: none;
                    }
                    width: 100%;
                    left: 0;
                    text-align: left;
                    &.animate-label {
                        top: 0px;
                        bottom: unset;
                        font-size: 16px;
                    }
                    @include media-breakpoint-down(md){
                        font-size: 20px;
                    }
                }
                input {
                    color: $white!important;
                    border: none;
                    border-radius: 0;
                    border-bottom: 1px solid $white;
                    padding-left: 0;
                    height: 44px;
                    background-color: transparent!important;
                    font-size: 24px;
                    font-weight: 200;
                    line-height: 38px;
                }

                input:-webkit-autofill,
                input:-webkit-autofill:hover, 
                input:-webkit-autofill:focus {
                    border: none none solid none;
                    -webkit-text-fill-color: $white;
                    -webkit-box-shadow: 0 0 0px 1000px $gray-d2 inset;
                    transition: background-color 5000s ease-in-out 0s;
                }

                .form-control:focus {
                    box-shadow: none;
                }
            }
        }

    }
}


body:not(.page-homepage) {
    .modal-login {
        .nav {
            padding: 36px 32px;
            @include media-breakpoint-down(sm) {
                padding: 36px 17px;
            }
        }
    }
}