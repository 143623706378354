.page-authorpage{
    .artist-detail{
        .img{
            height: 414px;
            width: 100%;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .text {
            h1{
                font-family: "Playfair Display";
                font-size: 3rem;
                font-weight: 900;
                line-height: 5.3rem;
                text-shadow: 0 2px 5px 0 rgba(0,0,0,0.5);
                color: $gray-d2;
                margin-top: 30px;
                text-align: center;
            }
            .bio {
                padding: 0 3rem;
                .bbcode_artist {
                    color: $blue;
                    font-weight: 600;
                    text-decoration: none;
                    &:hover {
                        opacity: .8;
                    }
                }
            }
        }
        .songlist {
            margin-top: 40px;
            padding-bottom: 80px;
            .list-header{
                margin-bottom: 20px;
            }
            h2{
                font-family: "Playfair Display";
                font-size: 3.2rem;
                font-weight: 900;
                line-height: 4.3rem;
                text-shadow: 0 2px 5px 0 rgba(0,0,0,0.5);
                color: $gray-d2;
                margin-top: 40px;
                margin-bottom: 2.4rem;
                border-bottom: 1px solid $gray-l1;
                padding-bottom: 12px;
            }
            .artist-song a{
                font-family: "Nunito Sans";	
                font-size: 1.6rem;	
                font-weight: 500;	
                line-height: 3.7rem;
                border-bottom: 1px solid $gray-l1;
                display: block;
                width: 100%;
                color: $gray-d2;
    
                span{
                    float: right;
                }
            }
        }
        .artist-info {
            padding: 0 7rem;
            @include media-breakpoint-down(xs) {
                padding: 0;
            }
        }
    }
}