
.song-add-to-songbook-container {
    &[disabled] {
        pointer-events: none;
        opacity: 0.7;
    }
    margin-bottom: 12px;
    &.songbook-open {
        padding: 0;
        ~ .song-list &:not(.favourites-list){
            display: none;
        }
        .songbooks{
            ul{
                width: 100%;
                li{
                    span{
                        font-size: 16px;
                    }
                    &.new-book{
                        svg{
                            height: 20px;
                        }
                    }
                    .create-new-songbook{
                        border-radius: 0px 0px 8px 8px;
                        margin-top: -1px;
                        .close{
                            height: 26px;
                            svg{
                                height: 20px;
                                margin-right: 0 !important;
                            }
                        }
                    }
                }
            }
        }
    }
    .song-add-to-songbook{
        position: relative;        
        .btn-icon-after{
            padding: 10px 45px 10px 10px;
            color: $white;
            position: relative;
            width: 100%;
            border-color: $green;
            background-color: $green;
            z-index: 1;
            @include media-breakpoint-down(md) {
                padding: 16px;
                width: 100%;
            }
            &:focus {
                outline: none;
                box-shadow: none;
            }
            .add{
                font-weight: 600;
            }
            .svg-container{
                border: 1px solid $white;
                border-radius: 4px;
                width: 20px;
                height: 20px;
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                pointer-events: none;
                @include media-breakpoint-down(md) {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    transform: none;
                    border: none;
                    top: 0;
                    width: 100%;
                    height: 100%;
                }
            }
            svg {
                width: 20px;
                @include media-breakpoint-down(md){
                    width: 38px;
                }
                g {
                    path {
                        &:nth-child(1) {
                            fill: $white;
                        }
                        &:nth-child(2) {
                            stroke: $white;
                        }
                    }
                    circle {
                        fill: unset;
                    }
                }
            }
        }
        &.open{
            @include media-breakpoint-down(md){
                position: fixed;
                top: 0;
                left: 0;
                width: 100vw;
                z-index: 100;
            }
            @include media-breakpoint-down(md){       
                span.add{
                    display: block !important;
                    font-size: 16px;
                    font-weight: 700;
                }
            }
            .songbooks{
                display: block;
                z-index: 10;
                background-color: $white;
                box-shadow: 0 0 20px 0 rgba(0,0,0,0.15);
                display: flex;
                justify-content: center;
                body.dark-mode & {
                    background-color: $black-mode-bg;
                }
                @include media-breakpoint-down(md){
                    width: 100% !important;
                    right: 0;
                    min-width: 200px;
                }
                ul{
                    overflow: scroll;
                    max-height: 370px;
                    margin-bottom: 0;
                    @include media-breakpoint-down(md){
                        max-height: 100vh;
                        width: 90%;
                        padding-bottom: 200px;
                    }
                    li{
                        &.new-book{
                            @include media-breakpoint-down(md){
                                padding-left: 0;    
                            }
                        }
                        .new-songbook{
                            @include media-breakpoint-down(md){
                                max-width: 90vw;
                                &.add-new-open{
                                    z-index: 1;                            
                                    position: fixed;
                                    bottom: 20px;
                                    left: 20px;
                                }
                                .create-new-songbook{
                                    border-radius: 8px;
                                }
                            }
                            .add-songbook{
                                padding: 16px 0 0 0;
                            }
                            svg{
                                padding-left: 0;
                            }
                        }
                    }
                }
            }
            .btn {
                min-width: 240px;
                border-bottom: 0px;
                text-align: left !important;
                border-radius: 4px 4px 0px 0px;
                @include media-breakpoint-down(sm){
                    width: 100vw;
                    padding: 16px 32px !important;
                }
                svg{
                    right: 6px;
                    position: absolute;
                    @include media-breakpoint-down(md){
                        width: 28px;
                    }
                }
            }
            button.close{
                bottom: unset;
                right: 6%;
                top: 10%;
                @include media-breakpoint-down(md){
                    right: 2%;
                }
            }
            button.save-songbook{
                min-width: unset;
                @include media-breakpoint-down(md){
                    max-width: 81vw;
                }
            }
        }
        .songbooks{
            position: absolute;
            display: none;
            border-radius: 0px 0px 4px 4px;
            width: 100%;
            ul {
                list-style: none;
                padding: 0;
                li {
                    display: flex;
                    padding: 6px 0px 6px 16px;
                    border-bottom: 1px solid $gray-l3;
                    min-height: 44px;
                    height: auto;
                    align-items: center;
                    .input{
                        border-radius: 2px;
                        width: 15px;
                        height: 15px;
                        margin-right: 6px;
                        border: 1px solid #999999;
                        position: relative;
                        &.checked{
                            &::before{
                                content: '';
                                width: 15px;
                                height: 15px;
                                position: absolute;
                                top: -1px;
                                left: -1px;
                                background-color: $blue;
                                border-radius: 2px;
                            }
                            &::after{
                                content: '';
                                position: absolute;
                                width: 7px;
                                height: 10px;
                                left: 3px;
                                transform: rotate(45deg);
                                border-bottom: 2px solid $white;
                                border-right: 2px solid $white;
                            }
                        }
                    }
                    #chosen-songbook{
                        position: absolute;
                        left: -100vw;
                    }
                    body.dark-mode & {
                        background-color: $black-mode-bg !important;
                    }
                    svg {
                        &:nth-child(1) {
                            min-width: 11px;
                            width: 11px;
                            margin-right: 9px; 
                        }
                        &:nth-last-child(1) {
                            min-width: 26px;
                            width: 26px;
                            margin-left: auto;
                            padding-left: 9px;
                        }
                    }
                    &:last-child{
                        border-bottom: none;
                    }
                    &.active {
                        span {
                            color: $green;
                        }
                    }
                    &:not(.active) {
                        padding-left: 16px;
                    }
                }
                li:hover {
                    cursor: pointer !important;
                }
            }
            .save-btn{
                background-color: $green;
                border-radius: 2px;
                height: 48px;
                width: 100%;
                border: none;
                color: $white;
                position: absolute;
                bottom: 0;
                max-width: 216px;
                margin-bottom: 16px;
                font-weight: 700;
            }
        }
    }
}

body.dark-mode{
    .song-add-to-songbook{
        .songbooks {
            ul {
                background-color: $black-mode-bg;
            }
        }
    }
    .btn-song-controls {
        svg {
            g {
                path {
                    fill: unset;
                }
            }
        }
    }
}