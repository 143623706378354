.chords-wrapper, .gp-tooltip {
    &.chord-color-black{
        .pref-chosen{
            .inner-container svg{
                #grid line{
                    stroke: $chord-color-black !important;
                }
                #dots circle{
                    fill: $chord-color-black !important;
                }
                #dots text{
                    fill: $chord-color-black !important;
                }
                #X text{
                    fill: $chord-color-black !important;
                }
            }
        }
    }
    &.chord-color-red{
        .pref-chosen{
            .inner-container svg{
                #grid line{
                    stroke: $chord-color-red !important;
                }
                #dots circle{
                    fill: $chord-color-red !important;
                }
                #dots text{
                    fill: $chord-color-red !important;
                }
                #X text{
                    fill: $chord-color-red !important;
                }
            }
        }
    }
    &.chord-color-purple{
        .pref-chosen{
            .inner-container svg{
                #grid line{
                    stroke: $chord-color-purple !important;
                }
                #dots circle{
                    fill: $chord-color-purple !important;
                }
                #dots text{
                    fill: $chord-color-purple !important;
                }
                #X text{
                    fill: $chord-color-purple !important;
                }
            }
        }
    }
    &.chord-color-blue{
        .pref-chosen{
            .inner-container svg{
                #grid line{
                    stroke: $chord-color-blue !important;
                }
                #dots circle{
                    fill: $chord-color-blue !important;
                }
                #dots text{
                    fill: $chord-color-blue !important;
                }
                #X text{
                    fill: $chord-color-blue !important;
                }
            }
        }
    }
    &.chord-color-orange{
        .pref-chosen{
            .inner-container svg{
                #grid line{
                    stroke: $chord-color-orange !important;
                }
                #dots circle{
                    fill: $chord-color-orange !important;
                }
                #dots text{
                    fill: $chord-color-orange !important;
                }
                #X text{
                    fill: $chord-color-orange !important;
                }
            }
        }
    }
}
.chord-container{
    &.chord-color-black{
        .selected-chord{
            .inner-container svg{
                #grid line{
                    stroke: $chord-color-black !important;
                }
                #dots circle{
                    fill: $chord-color-black !important;
                }
                #dots text{
                    fill: $chord-color-black !important;
                }
                #X text{
                    fill: $chord-color-black !important;
                }
            }
        }
    }
    &.chord-color-red{
        .selected-chord{
            .inner-container svg{
                #grid line{
                    stroke: $chord-color-red!important;
                }
                #dots circle{
                    fill: $chord-color-red!important;
                }
                #dots text{
                    fill: $chord-color-red!important;
                }
                #X text{
                    fill: $chord-color-red!important;
                }
            }
        }
    }
    &.chord-color-purple{
        .selected-chord{
            .inner-container svg{
                #grid line{
                    stroke: $chord-color-purple !important;
                }
                #dots circle{
                    fill: $chord-color-purple !important;
                }
                #dots text{
                    fill: $chord-color-purple !important;
                }
                #X text{
                    fill: $chord-color-purple !important;
                }
            }
        }
    }
    &.chord-color-blue{
        .selected-chord{
            .inner-container svg{
                #grid line{
                    stroke: $chord-color-blue !important;
                }
                #dots circle{
                    fill: $chord-color-blue !important;
                }
                #dots text{
                    fill: $chord-color-blue !important;
                }
                #X text{
                    fill: $chord-color-blue !important;
                }
            }
        }
    }
    &.chord-color-orange{
        .selected-chord{
            .inner-container svg{
                #grid line{
                    stroke: $chord-color-orange !important;
                }
                #dots circle{
                    fill: $chord-color-orange !important;
                }
                #dots text{
                    fill: $chord-color-orange !important;
                }
                #X text{
                    fill: $chord-color-orange !important;
                }
            }
        }
    }
}
.svg-chord-container {
    display: inline-block;
    height: auto;
    width: 7rem;
    position: relative;
    &.piano {
        padding: 5px 0;
        svg {
            text#title {
                font-size: 25px;
            }
        }
    }

    &.smaller {
        min-width: 5rem;
        width: auto;
        margin-right: 10px;
        &.piano {
            width: 20rem;
            .inner-container {
                width: 100%;
            }
        }
        .inner-container {
          width: 5.5rem;
        }
        text#title {
            font-size: 11px;
        }
    }
    &.small {
        min-width: 6rem;
        width: auto;
        &.piano {
            width: 22.5rem;
            .inner-container {
                width: 100%;
            }

        }
        .inner-container {
          width: 6.5rem;
        }
        text#title {
            font-size: 12px;
        }
    }
    &.normal {
        min-width: 7rem;
        width: auto;
        &.piano {
            width: 25rem;
            .inner-container {
                width: 100%;
            }
        }
        .inner-container {
          width: 7.5rem;
        }
        text#title {
            font-size: 13px;
        }
    }
    &.big {
        width: 8rem;
        &.piano {
            width: 27.5rem;
            .inner-container {
                width: 100%;
            }
        }
        text#title {
            font-size: 14px;
        }
    }
    &.bigger {
        width: 8.7rem;
        &.piano {
            width: 30rem;
            .inner-container {
                width: 100%;
            }
        }
        text#title {
            font-size: 15px;
        }
    }

    .inner-container {
      margin-top: -15px;
    }

    text#title {
        margin-left: 10px;
        font-size: 13px;
        font-weight: bold;
        font-family: helvetica, sans-serif;
    }

    .set-default-container {
        z-index: 10;
        background-color: $white;
        padding: 4px 8px;
        border-radius: 4px;
        position: absolute;
        bottom: -20%;
        right: calc(50% - 44px);
        white-space: nowrap;
        -webkit-box-shadow: 0px 10px 11px -8px rgba(0,0,0,0.21);
        -moz-box-shadow: 0px 10px 11px -8px rgba(0,0,0,0.21);
        box-shadow: 0px 10px 11px -8px rgba(0,0,0,0.21);
        padding-left: 10px;
        body.dark-mode & {
            background-color: $black;
        }
        &:hover{
            font-weight: 600;
        }
    }

    &.ukulele  {
        .inner-container {
            margin-top: -25px;
        }
    }

    svg {
        width: 100%;
        height: auto;

        #fret-0 {
            stroke-width: 2;
        }
        text#title {
            display: none;
        }
    }


    body.page-songpage.dark-mode & {
        &.piano {
            .white {
                stroke: $gray;
                fill: transparent;
            }
            circle.white {
                fill: $black-mode-bg;
            }
            .black {
                fill: $gray;
            }
        }
    }
}
.chord-container.user-pref .svg-chord-container {
    &:hover {
        cursor: pointer;
        .inner-container svg{
            #grid line{
                stroke: $gray-d1 !important;
            }
            #dots circle{
                fill: $gray-d1 !important;
            }
            #dots text{
                fill: $gray-d1 !important;
            }
            #X text{
                fill: $gray-d1 !important;
            }
        }
    }
    body.dark-mode &{
        &:hover {
            cursor: pointer;
            .inner-container svg{
                #grid line{
                    stroke: $white !important;
                }
                #dots circle{
                    fill: $white !important;
                }
                #dots text{
                    fill: $white !important;
                }
                #X text{
                    fill: $white !important;
                }
            }
        }
    }
}

.chords-fixed {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: $white-d2;
    height: 125px;
    border-top: 1px solid $gray-l4;
    padding-top: 15px;
    overflow-y: scroll;
    z-index: 9;

    body.dark-mode & {
        background-color: $black-mode-bg;
        border-top-color: $gray-d3;
    }
}