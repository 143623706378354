.step-indicator {
    margin-top: 26px;
    margin-bottom: 40px;
    hr {
      border-top: 1px solid $gray-d3;
      width: 100%;
    }
    .highlight {
      border-color: $red;
    }
  }