.pagination {
    margin-top: 32px;
    margin-bottom: 32px;
    ul {
        list-style: none;
        padding: 0px;
        display: flex;
        padding-left: 15px;
        width: 100%;
        justify-content: space-between;
        &>span {
            overflow-x: scroll;
            padding-bottom: 10px;
            &>span {
                display: flex;
                flex-wrap: nowrap;
                min-width: 555px;
                li {
                    padding-right: 10px;
                    &:nth-last-child(1) {
                        padding-right: 0px;
                    }
                }
            }
        }
        li {
            &.disabled {
                display: none !important;
                svg {
                    width: 24px;
                    height: auto;
                    opacity: .4;
                }
            }
            &.active {
                color: $blue; 
                font-weight: 600;
            }
            &.prev {
                padding-right: 30px;
                a {
                    width: 24px;
                    display: block;
                }
            }
            &.next {
                padding-right: 0px;
                padding-left: 30px;
                a {
                    width: 24px;
                    display: block;
                }
            }
            a {
                color: $gray-d2;
                font-weight: 600;
                &:hover {
                    text-decoration: none;
                }
                svg {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}

.pagination-react {
    .pagination {
        justify-content: center;
      }
      
      ul {
        list-style: none;
        padding: 0;
      }
      
      ul.pagination li {
        display: inline-block;
        width: 30px;
        border: 1px solid #e2e2e2;
        display: flex;
        justify-content: center;
        font-size: 25px;
      }

      ul.pagination li.disabled {
        display: none;
      }
      
      ul.pagination li a {
        text-decoration: none;
        color: $black;
        font-size: 20px;
      }
      
      ul.pagination li.active a {
        color: white;
      }
      ul.pagination li.active {
        background-color: $green-d1;
      }
      
      ul.pagination li a:hover,
      ul.pagination li a.active {
        color: $green-d1;
      }
      
      .page-selection {
        width: 48px;
        height: 30px;
        color: #337ab7;
      }
      
      .pagination-wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
      }
}