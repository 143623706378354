.wishlist {
    .wishlist-form {
        flex-direction: column;
        .error{
          color: $error;
        }
        input, textarea{
          width: 100%;
          border-radius: 3.2px;
          body.dark-mode & {
            background-color: $gray-d2;
            color: $white;
          }
        }
        label{
          margin-top: 30px;
          margin-bottom: 0;
        }
        input{
          height: 30px;
          border: 1px solid $gray;
        }
        textarea{
          height: 300px;
        }
        .submit-song{
          padding: 12px 20px;
          font-size: 14px;
          color: $white;
          background-color: $red;
          border-radius: 30px;
          font-weight: 700;
          margin: 30px auto;
        }
      }
      .title-cover {
          position: relative;
          &:first-of-type {
            div {
                font-weight: 600;
                font-size: 1.8rem;
            }
          }
          &.row {
            border-bottom: 1px solid $gray-l1;
            padding-top: 10px;
            padding-bottom: 10px;
          }
  
          div {
            font-family: "Nunito Sans";	
            font-size: 1.6rem;	
            font-weight: 500;	
            line-height: 3.7rem;
            
            display: block;
            width: 100%;
            color: $gray-d2;

           
            span{
                float: right;
            }
          }
          .submit-vote {
            padding: 0px 8px;
            font-size: 14px;
            color: #FFFFFF;
            background-color: #ec4365;
            font-weight: 700;
            border: none;
            &:hover {
                cursor: pointer;
            }
          }
      }
}