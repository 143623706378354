.profile{
    .information{
        .info-wrapper{
            .button-modal-subscription{
                margin: 25px auto;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            button {
              a {
                color: white;
              }
              a:link {
                text-decoration: none;
              }
            }
        }
    }
    .options {
        border-left: 1px solid $gray-l4;
        .info-wrapper {
            &.language {
                position: relative;
                p {
                    font-family: "Nunito Sans";
                    font-size: 2.4rem;
                    font-weight: 300;
                    line-height: 3.8rem;
                    max-width: 70%;
                    word-break: break-all;
                    @include media-breakpoint-down(lg) {
                        font-size: 2rem;
                    }
                }
                button {
                    position: absolute;
                    width: auto;
                    padding: 6px 16px;
                    border-radius: 20px;
                    top: 45px;
                    right: 0px;
                }
                .checkboxes-container {
                    display: flex;
                    flex-wrap: wrap;
                }
                .checkbox {
                    display: flex;
                    width: calc(100% / 3);
                    margin-bottom: 10px;
                    input {
                        margin-right: 10px;
                        width: 20px;
                        height: 20px;
                    }
                }
            }
            &.delete{
                margin-top: auto;
                position: absolute;
                bottom: 0;
                width: 100%;
                @include media-breakpoint-down(xs){
                    position: relative !important;
                }
                button{
                    background-color: $error;
                }
            }
        }
    }
    
    .form-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        @include media-breakpoint-down(lg) {
            flex-direction: column;
        }
        .form-check {
            padding-left: 18px;
            @include media-breakpoint-down(lg) {
                &:nth-last-child(1) {
                    margin-top: 8px;
                }
            }
            input {
                margin-right: 12px;
                position: relative;
                width: 10px;
                height: 10px;
                &:before {
                    content: "";
                    height: 20px;
                    width: 20px;
                    max-width: 20px;
                    border-radius: 50%;
                    cursor: pointer;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    border: solid 1px $gray-l2;
                    background-color: $white;
                }
                &:checked {
                    &:before {
                        border-color: $gray-l4;
                    }
                    &:after {
                        content: "";
                        background-color: $red;
                        width: 12px;
                        height: 12px;
                        position: absolute;
                        border-radius: 50%;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }
    }
    .username-wrapper{
        padding-top: 50px;
        padding-bottom: 20px;
        border-bottom: 1px solid $gray-l4;
    }
    .info-wrapper{
        padding: 20px 0px;
        border-bottom: 1px solid $gray-l4;
        &:nth-last-child(1) {
            border-bottom: none;
        }
    }
    .label, label{
        color: $gray-d1;
        font-family: "Nunito Sans";
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 1.9rem;
    }
    .info.contrib-songs {
        &:hover {
            cursor: pointer;
            font-weight: 500;
        }
    }
    .username, .info{
        color: $gray-d2;
        font-family: "Nunito Sans";
        font-size: 2.4rem;
        font-weight: 300;
        line-height: 3.8rem;
        @include media-breakpoint-down(lg) {
            font-size: 2rem;
        }
        svg{
            height: 24px;
            body.dark-mode & {
                path{
                    fill: $white;
                }
            }
        }
    }
    input[type=text], input[type=password]{
        height: 45px;
        width: 100%;
        border: 1px solid $gray-l2;
        background: transparent;
        &:focus{
            background: $white;
        }
        margin-bottom: 20px;
    }
    button{
        border-radius: 4px;
        background: $gray;
        font-size: 16px;
        font-weight: 600;
        color: $white;
        border: none;
        width: 100%;
        padding: 11px;
    }
    .buy-button {
        line-height: unset;
    }
}