.new-songbook {
    button.add-songbook {
        margin: 0 auto 12px;
        border: none;
        display: flex;
        background-color: transparent;
        box-shadow: none;
        padding: 16px 0 0 16px;
        width: 100%;
        body.page-songbookpage &{
            padding: 16px 0 0 0;
        }
        &:focus {
            outline: none;
        }
        svg {
            margin-top: 3px;
            margin-right: 9px;
            height: 16px;
            g {
                path:nth-child(1) {
                    fill: $red;
                }
                path:nth-child(2) {
                    stroke: $red;
                }
            }
        }
    }
    .create-new-songbook {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        border-radius: 0;
        padding: 20px 18px;
        height: 134px;
        background-color: $red;
        box-shadow: 0 0 20px 0 rgba(0,0,0,0.15);
        button.close {
            position: absolute;
            padding: 0 !important;
            bottom: 36%;
            right: -2%;
            opacity: 1;
            svg {
                height: 26px;
                width: 26px;
                circle{
                    fill: transparent;
                }
            }
        }

        label {
            color: $white !important;
        }

        label {
            color: $white !important;
            font-family: "Nunito Sans";
            font-size: 14px;
            line-height: 19px;
            margin-bottom: 0px;
            padding-bottom: 12px;
            svg {
                width: 24px;
                margin-right: 9px;
                path {
                    fill: $white;
                    &:nth-child(2) {
                        stroke: $white;
                    }
                }
            }
        }
        input {
            border: 1px solid $white;
            background-color: rgba($white, .1);
            border-radius: 4px;
            color: $white;
            padding: 3px 10px;
            &:focus {
                outline: none!important;
            }
        }
        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus {
            border: 1px solid $white;
            -webkit-text-fill-color: $white;
            -webkit-box-shadow: 0 0 0px 1000px rgba($white, .1) inset;
            transition: background-color 5000s ease-in-out 0s;
            outline: none!important;
        }
        button.save-songbook {
            border: none;
            background-color: transparent;
            font-size: 14px;
            font-weight: 600;
            line-height: 19px;
            color: white;
            padding: 6px;
            margin: 0px;
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 8px;
            &:focus {
                outline: none;
            }
        }
    }
}