.autoscroll {
    z-index: 100;
    width: 100%;
    position: absolute;
    height: 48px;
    background-color: white;
    top: 100%;
    border-radius: 0px 0px 8px 8px !important;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.15);
    padding: 12px;
    display: none;
    justify-content: space-between;
    align-items: center;
    @include media-breakpoint-down(md) {
        width: calc(100vw - 34px);
        right: 0;
        max-width: 235px;
    }
    @include media-breakpoint-down(sm) {
        border-radius: 8px;
        max-width: 300px;
        width: 100%;
    }
    @media (max-width: 500px) {
        border-radius: 8px;
        max-width: unset;
        width: 100%;
    }
    &.open{
        display: flex;
    }
    .speed {
        background-color: $gray;
        width: calc(100% - 30px);
        height: 17px;
        position: relative;
        cursor: pointer;
        &:after {
            position: absolute;
            content: "";
            width: 100%;
            -ms-transform: skewY(-5deg);
            transform: skewY(-4deg);
            top: -9px;
            height: 100%;
            background-color: $white;
            @include media-breakpoint-down(md) {
                clip-path: polygon(0% 0%, 0% 90%, 106% 0%);
                transform: none !important;
                top: 0;
            }
        }
        .line {
            position: absolute;
            width: 5px;
            height: calc(100% + 6px);
            background-color: $red;
            z-index: 1;
            left: 50%;
            margin-top: -3px;
            cursor: move;
            cursor: grab;
            cursor: -moz-grab;
            cursor: -webkit-grab;
            &:active, &:focus{
                box-shadow: none;
                cursor: grabbing;
                cursor: -moz-grabbing;
                cursor: -webkit-grabbing;
                outline: none;
            }
            &::before{
                position: absolute;
                content: ' ';
                left: -14px;
                right: -14px;
                top: -6px;
                bottom: -6px;
            }
        }
    }
    .stop {
        width: 14px;
        height: 14px;
        background-color: $red;
        cursor: pointer;
    }
    .play {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 7px 0 7px 14px;
        border-color: transparent transparent transparent $red;
        cursor: pointer;
        
        &.disabled {
            border-color: transparent transparent transparent $gray;
            pointer-events: none;
        }
    }
}

.dark-mode{
    .autoscroll{
        background-color: $gray-d2;
        .speed{
            &:after{
                background-color: $gray-d2;
            }
        }
    }
}