.not-visible{
    transform: translateX(100%);
}
.timer-visible{
    transform: translateX(0);
    transition: transform linear 1s;
    position: fixed;
    right: 0;
    bottom: 300px;
    z-index: 10;
    @media (max-width: 1440px){
        bottom: 120px;
    }
    @include media-breakpoint-down(md){
        bottom: 80px;
    }
}
.countdown-timer {
    z-index: 10;
    width: 300px;
    border-radius: 15px;
    padding: 20px;
    background-color: $white;
    @include media-breakpoint-down(md){
        width: 200px;
    }
    @include media-breakpoint-down(sm){
        width: 120px;
    }
    h2.next-song-title{
        line-height: 28px;
        margin-bottom: 20px;
        margin-top: 10px;
        font-size: 26px;
        @include media-breakpoint-down(md){
            font-size: 14px;
            line-height: 22px;
            word-break: break-word;
            margin-bottom: 10px;
        }
    }
    .countdown-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        .countdown-item {
            color: $black-mode-bg;
            font-size: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            line-height: 30px;
            margin: 50px 10px;
            padding-top: 10px;
            height: 100px;
            width: 100px;
            position: relative;
            @include media-breakpoint-down(md){
                font-size: 26px;
                width: 50px;
                height: 50px;
                margin: 30px 0;
            }
            span {
                color: $black-mode-bg;
                font-size: 12px;
                font-weight: 600;
                text-transform: uppercase;
                @include media-breakpoint-down(md){
                    font-size: 10px;
                    margin-top: -10px;
                }
            }
        }
    }
    .countdown-actions {
        margin-top: 1em;
        display: flex;
        justify-content: center;
        column-gap: 10em;
    }
    .stop-countdown, .pause-countdown {
        cursor: pointer;
    }
}

svg.timer {
    background-color: transparent;
    position: absolute;
    background-color: transparent;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    @include media-breakpoint-down(md){
        width: 100px;
        height: 100px;
    }

    circle {
        stroke-dashoffset: 0;
        transition: stroke-dashoffset 1s linear;
        stroke: transparent;
        stroke-width: 10px;
    }
    #bar {
        stroke: $green-d1;
    }
}

// @keyframes circletimer {
//     from {
//         stroke-dashoffset: 0px;
//         -webkit-stroke-dashoffset: 0px;
//         stroke-dasharray: 800px;
//         -webkit-stroke-dasharray: 800px;
//     }
//     to {
//         stroke-dashoffset: 800px;
//         -webkit-stroke-dashoffset: 800px;
//         stroke-dasharray: 800px;
//         -webkit-stroke-dasharray: 800px;
//     }
// }
// @include media-breakpoint-down(md){
//     @keyframes circletimer {
//         from {
//             stroke-dashoffset: 0px;
//             -webkit-stroke-dashoffset: 0px;
//             stroke-dasharray: 650px;
//             -webkit-stroke-dasharray: 650px;
//         }
//         to {
//             stroke-dashoffset: 650px;
//             -webkit-stroke-dashoffset: 650px;
//             stroke-dasharray: 650px;
//             -webkit-stroke-dasharray: 650px;
//         }
//     }
// }
// svg.timer{
//     background-color: transparent;
//     position: absolute;
//     background-color: transparent;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     -webkit-transform: translate(-50%, -50%);
//     @include media-breakpoint-down(md){
//         width: 100px;
//         height: 100px;
//     }
//     .circle{
//         stroke: $green-d1;
//         stroke-width: 12px;
//         fill: transparent;
//         stroke-dashoffset: 800px;
//         stroke-dasharray: 0;
//         animation: 5s circletimer linear 1.5s;
//         -webkit-animation: 5s circletimer linear 1.5s;
//         @include media-breakpoint-down(md){
//             stroke-dashoffset: 650px;
//             width: 100px;
//             height: 100px;
//             cx: 50;
//             cy: 50;
//             r: 40;
//             stroke-width: 8px;
//         }
//     }
// }
body.dark-mode{
    .countdown-timer{
        background-color: $black-mode-bg-nav;
    }
    .circle{
        stroke: $red-dark-mode;
    }
    .timer{
        color: $red-dark-mode;
    }
}