//background colors
$black-mode-bg-nav: #111111;
$black-mode-bg: #222222;

//colors
$dark-mode-white: rgba(255, 255, 255, .9);
$white-50: rgba(255,255,255, .5);
$white: #FFFFFF;
$white-d1: #FAFAFA;
$white-d2: #f4f4f4;
$white-d3: #f3f3f3;

$gray-l4: #eaeaea;
$gray-l3: #DCDCDC;
$gray-l2: #EEEEEE;
$gray-l1: #D8D8D8;
$gray: #B5B5B5;
$gray-d1: #606060;
$gray-d2: #2B2B2B;
$gray-d2-70: rgba(43, 43, 43, .2);
$gray-d3: #525252;
$gray-d4: #494949;

$black: #000;

$fb-blue: #3B5998;
$blue: #61AEB7;
$error: #ff0000;
$red: #ec4365;
$red-dark-mode: #c03652;
$green: #8AAEB2;
$green-90: rgba(138, 174, 178, .9);
$green-d1: #618B8F;
$green-dark-mode: #374546;


//chord colors
$chord-color-blue: #61AEB7;
// $chord-color-red: #FE1313;
$chord-color-red: #E2414F;
$chord-color-black: #2B2B2B;
$chord-color-purple: #9013FE;
$chord-color-orange: #F5A623;