.chord-color-selector {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    .radio {
        height: calc(((338px - 40px) - (20px * 4)) / 5);
        flex-grow: 1;
        border-radius: 2px;
        cursor: pointer;
        position: relative;
        margin-right: 20px;
        margin-bottom: 0px;

        @media (max-width: 384px) {
            height: calc((((100vw - 46px) - 40px) - (20px * 4)) / 5);
        }

        &.black {
            background-color: $chord-color-black
        }

        &.red {
            background-color: $chord-color-red;
            margin-right: 0px;
        }

        &.purple {
            background-color: $chord-color-purple
        }

        &.blue {
            background-color: $chord-color-blue
        }

        &.orange {
            background-color: $chord-color-orange
        }

        &.active {
            background-image: url('../img/checkmark.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 12px;
        }

        input[type="radio"] {
            display: none;
        }
    }
}

.song {
    &.chord-color-black {
        .chordline span strong {
            color: $chord-color-black;
        }
    }

    &.chord-color-red {
        .chordline span strong {
            color: $chord-color-red;
        }
    }

    &.chord-color-purple {
        .chordline span strong {
            color: $chord-color-purple;
        }
    }

    &.chord-color-blue {
        .chordline span strong {
            color: $chord-color-blue;
        }
    }

    &.chord-color-orange {
        .chordline span strong {
            color: $chord-color-orange;
        }
    }
}

.chord-container,
.gp-tooltip {
    &.chord-color-black {
        text#title {
            color: $chord-color-black;
        }
    }

    &.chord-color-red {
        text#title {
            color: $chord-color-red;
        }
    }

    &.chord-color-purple {
        text#title {
            color: $chord-color-purple;
        }
    }

    &.chord-color-blue {
        text#title {
            color: $chord-color-blue;
        }
    }

    &.chord-color-orange {
        text#title {
            color: $chord-color-orange;
        }
    }
}

.gp-tooltip {
    position: fixed;
    background: $white;
    width: 80px;
    height: 115px;
    z-index: 9;
    padding: 5px;
    border-radius: 4px;
    box-shadow: '0 4px 14px 0 rgba(0, 0, 0, 0.15)';

    body.dark-mode & {
        background: $black-mode-bg;
        border: 1px solid $gray-d3;

        svg {
            #grid line {
                stroke: $gray;
            }

            #dots circle {
                fill: $gray;
            }

            #dots text {
                fill: $gray;
            }

            #X text {
                fill: $gray;
            }
        }
    }
}