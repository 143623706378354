.song{
    &.font-size-bigger{font-size: 22px;}
    &.font-size-big{font-size: 20px}
    &.font-size-normal{font-size: 18px;}
    &.font-size-small{font-size: 16px;}
    &.font-size-smaller{font-size: 14px;}
}

.song-size-selector{
    .label-small{
        font-size: 11.25px;
    }
    .label-large{
        font-size: 22.5px;
    }
}