.ReactModal__Overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1440px){
        .nav{
            padding: 31px 40px 0;
        }   
    }
    @include media-breakpoint-down(md) {
        z-index: 100 !important;
        margin-top: -8px;
    }
}
body.dark-mode {
   .modal-usersongs {
        background-color: $gray-d2;
        .text-container {
            button.close span {
                background-color: $white;
            }
        }

   }
}
.modal-usersongs {
    overflow: scroll;
    background-color: $white;
    width: 50vw;
    position: relative;
    max-width: 550px;
    height: auto;
    max-height: 650px;
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.15);
    z-index: 10;
    border: $black;
    @include media-breakpoint-down(xxl) {
        width: 80vw;
    }
    @include media-breakpoint-down(xl) {
        width: 100vw;
    }
    @include media-breakpoint-down(md) {
        width: 100%;
        height: 100%;
        max-height: unset;
        flex-direction: column;
    }
    button.close {
        width: 30px;
        height: 30px;
        opacity: 1;
        transform: translateY(-3px);
        position: sticky;
        top: 35px;
        span {
            width: 30px;
            height: 2px;
            display: block;
            margin-bottom: 6px;
            z-index: 2;
            background-color: $black;
            position: absolute;
            &:nth-child(1) {
                transform: rotate(45deg);
            }
            &:nth-last-child(1) {
                transform: rotate(-45deg);
            }
        }
    }
    .text-container {
        padding: 35px;
        a {
            font-size: 20px;
            line-height: 50px;
            color: $gray-d2;
            @include media-breakpoint-down(md) {
                font-size: 18px;
            }
            &:hover {
                text-decoration: none;
                font-weight: 600;
            }
        }
    }
}