.ReactModal__Content.pdf-modal-container{
  transform: translate(-50%, 5%) !important;
  @include media-breakpoint-down(md){
    top: 0 !important;
    transform: none !important;
    left: 0 !important;
    right: unset !important;
    margin-right: 0 !important;
    height: 100vh;
  }
  .nav {
    z-index: 1;
    button.close {
      width: 30px;
      height: 30px;
      opacity: 1;
      margin-left: auto;
      span {
          width: 30px;
          height: 2px;
          display: block;
          margin-bottom: 6px;
          z-index: 2;
          background-color: $white;
          position: absolute;
          &:nth-child(1) {
              transform: rotate(45deg);
          }
          &:nth-last-child(1) {
              transform: rotate(-45deg);
          }
      }
    }
  }
}
.songbook-pdf-modal {
  padding: 60px 60px 180px 60px;
  width: 100vw!important;
  max-width: 850px;
  background-color: $white;
  z-index: 10;
  border: $black;
  @include media-breakpoint-down(md){
    padding: 0 40px 100px 40px;
    height: 100%;
    overflow: scroll;
  }
  @include media-breakpoint-down(sm){
    padding: 20px;
  }
  body.dark-mode & {
    background-color: $black-mode-bg;
  }
  .title {
    font-family: "Playfair Display";
    font-size: 40px;
    font-weight: 900;
    color: $gray-d3;
    @include media-breakpoint-down(md){
      font-size: 32px;
      margin-top: 40px;
    }
  }
  .step-indicator{
    @include media-breakpoint-down(md){
        margin-top: 40px;
    }
  }

  button{
    cursor: pointer;
  }

  .content {
    @include media-breakpoint-down(md){
      a{
        margin: 20px 0;
      }
    }
    .subtitle {
      font-family: "Nunito Sans";
      font-weight: bold;
      text-align: center;
      margin-bottom: 20px;
    }
    .vertical-single { 
      width: 1px;
      background-color: $gray-l1;
      position: relative;
      margin: 0px 10px;
      @include media-breakpoint-down(md){
        display: none;
      }
      .or-box {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: $gray-d3;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 700;
        @include media-breakpoint-down(md){
          position: relative;
          margin: 40px 0;
        }
        @include media-breakpoint-down(sm){
          margin: 20px 0;
        }
      }
    }

    .alert{
      display: block;
      margin-top: -30px;
      border: 1px solid #8aaeb2;
      color: black;
      background-color: #8aaeb214;
    }

    .pdf-type {
      @include media-breakpoint-down(md){
        .row{
          align-items: flex-end;
          .option{
            text-align: center;
            padding: 0;
            max-width: 48%;
            margin-top: 30px;
          }
        }
      }
      .option {
        width: 282px;
        padding: 0px 40px;
      }
      svg{
        cursor: pointer;
      }
      .active {
        svg {
          border: 2px solid $red;
        }
      }
    }

    .chord-config {
      .row {
        padding: 20px 0px;
      }
      p {
        font-family: "Nunito Sans";
      }
      .selection {
        margin: 20px 0px;
        padding-left: 40px;
        cursor: pointer;
        @include media-breakpoint-down(md){
          padding: 0;
        }
        .select-wrapper {
          position: relative;
          margin-bottom: 30px;
          width: 275px;
          &:after {
            content: "";
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);
            background-image: url(../img/arrow_down_2.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            width: 13px;
            height: 13px;
          }
          select {
            width: 100%;
            height: 38px;
            border-radius: 22px;
            padding: 0px 12px;
            border-color: $gray;
            -moz-appearance: none;
            -webkit-appearance: none;
            position: relative;
            &::-ms-expand {
              display: none;
            }
            &:focus {
              outline: none;
            }
          }
        }
        .chord-color-selector{
          display: flex;
          justify-content: flex-start;
          margin-top: 10px;
          @include media-breakpoint-down(md){
            width: 120%;
          }
          input{
            visibility: hidden;
          }
          .radio{
            height: 20px;
            width: 20px;
            max-width: 20px;
            border-radius: 50%;
            cursor: pointer;
            position: relative;
            margin-right: 20px;
            &.black{
                background-color: $chord-color-black
            }
            &.red{
                background-color: $chord-color-red
            }
            &.purple{
                background-color: $chord-color-purple
            }
            &.blue{
                background-color: $chord-color-blue
            }
            &.orange{
                background-color: $chord-color-orange
            }
            &.active{
              background-image: url('../img/checkmark.svg');
              background-position: center;
              background-repeat: no-repeat;
              position: relative;
              &:after {
                content: "";
                border: 1px solid $gray-l1;
                width: 30px;
                height: 30px;
                position: absolute;
                border-radius: 50%;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
              }
            }
          }
        }
      }
      .text-pdf-img { 
        display: flex;
        padding-right: 30px;
        @include media-breakpoint-down(md){
          padding: 0;
          svg{
            margin: 0 auto !important;
          }
        }
        svg{
          margin: 20px 0px 20px auto;
        }
        &.red {
          svg {
            line.chords {
              stroke: $chord-color-red;
            }
          }
        }
        &.orange {
          svg {
            line.chords {
              stroke: $chord-color-orange;
            }
          }
        }
        &.blue {
          svg {
            line.chords {
              stroke: $chord-color-blue;
            }
          }
        }
        &.purple {
          svg {
            line.chords {
              stroke: $chord-color-purple;
            }
          }
        }
        &.black {
          svg {
            line.chords {
              stroke: $chord-color-black;
            }
          }
        }
      }
    }

    .page-setup{
      .content-wrapper {
        .labels-container {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          p:nth-child(1) {
            margin-bottom: 25px;
          }
          p:nth-last-child(1) {
            margin-bottom: 0px;
          }
        }
        .option-container {
          height: 217px;
          display: flex;
          flex-direction: column;
          margin-bottom: 30px;
          &:nth-last-child(1) {
            .radio-container {
              padding-bottom: 32px;
            }
          }
          &>p {
            margin-top: auto;
          }
        }
        .radio-container {
          display: flex;
          justify-content: space-evenly;
          position: relative;
          .radio {
            display: flex;
            flex-direction: row;
            input[type="radio"]{
              display: none;
              &+label {
                position: relative;
                margin-left: 40px;
                cursor: pointer;
                &:before {
                  content: "";
                  height: 20px;
                  width: 20px;
                  max-width: 20px;
                  border-radius: 50%;
                  cursor: pointer;
                  position: absolute;
                  top: 50%;
                  left: -30px;
                  transform: translateY(-50%);
                  margin-right: 20px;
                  border: solid 2px $gray;
                  background-color: $white;
                }
              }
              &:checked+label {
                &:before {
                  background-color: $red;
                  border-color: $red;
                  background-image: url(../img/checkmark.svg);
                  background-position: center;
                  background-repeat: no-repeat;
                }
                &:after {
                  content: "";
                  border: 1px solid $gray-l1;
                  width: 30px;
                  height: 30px;
                  position: absolute;
                  border-radius: 50%;
                  left: -35px;
                  top: 50%;
                  transform: translateY(-50%);
                }
              }
            }
          }
        }
      }
      .songs-per-page {
        justify-content: space-between;
      }
      .active {
        display: inline-block;
        border: 2px solid $red;
      }
      .hide {
        display: none;
      }
    }
  }
  .pn-buttons {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    @include media-breakpoint-down(md){
      position: relative;
      display: flex;
      justify-content: center;
      margin-top: 150px;
    }
    @include media-breakpoint-down(sm){
      margin-top: 50px;
      bottom: 0;
      margin-bottom: 50px;
    }
    .previous {
      display: block;
      border: none;
      background-color: transparent;
      margin-right: 12px;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 700;
      color: $gray;
      &.disabled {
        display: none;
      }
      svg {
        margin-right: 8px;
        width: 15px;
        height: 11px;
        path {
          stroke: $gray;
        }
      }
    }
    .next {
      height: 55px;
      width: 187px;
      border: none;
      border-radius: 30px;
      background-color: $red;
      color: $white;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 700;
    }
  }
}