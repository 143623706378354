.page-songbookpage {
  &.dark-mode {
    .songbooks {
      .btn-light {
        background-color: transparent;
        border: none;
        svg path {
          fill: $white;
          &:hover {
            fill: $red;
          }
        }
      }
      .button-modal-songbook {
        &:hover {
          svg path {
            fill: $red;
          }
        }
        svg path {
          fill: $white;
        }
      }
      .songbook-detail {
        .song {
          border-top: 1px solid $gray-d1;
        }
      }
    }
  }
  .songbook.row {
    max-width: 100%;
    margin: 0 auto;
    .actions {
      display: none;
    }
    &:first-child {
      border-top: none;
    }
  }
  & > .songbooks {
    @include media-breakpoint-down(md) {
      padding-top: 20px !important;
    }
  }
  .songbooks-search-container {
    position: absolute;
    top: -6px;
    right: 48px;
    @include media-breakpoint-down(sm) {
      z-index: 1;
      input.private:focus {
        width: 87vw !important;
        height: 53px;
      }
    }
    input.private {
      border-radius: 0 !important;
      width: 90px;
      padding-right: 10px;
      transition: width linear 0.3s;
      &:focus {
        width: 320px;
        transition: width linear 0.3s;
        border-bottom: 2px solid $red;
        font-weight: 800 !important;
        @media (min-width: 992px) and (max-width: 1200px) {
          width: 280px;
        }
        &::placeholder {
          text-align: left !important;
        }
      }
      &::placeholder {
        text-align: right;
        color: $gray-d2;
      } 
    }
  
    svg {
      margin-bottom: 3px;
      width: 17px;
      transform: rotate(90deg);
      path {
        fill: $gray-d2;
      }
    }
  }
  .nav-item.nav-link {
    text-transform: uppercase;
    color: $gray-d2;
    padding: 8px 0px;
    margin-right: 20px;
    border: none;
    margin-bottom: 40px;
    font-weight: 400;
    letter-spacing: 1px;
    &:active,
    &:focus {
      box-shadow: none;
      outline: none;
    }
    &.active {
      background-color: transparent;
      font-weight: 800;
      color: $gray-d2;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        bottom: -10px;
        height: 2px;
        width: 30px;
        background-color: $red;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    a {
      font-size: 16px !important;
    }
  }

  .create-new-songbook {
    border-radius: 8px;
    width: 220px !important;
    .close {
      top: 10px !important;
      right: 10px !important;
      bottom: unset !important;
    }
  }
  .toplists {
    .song-list.songbooks {
      padding-top: 0;
    }
    .song-list {
      .item {
        padding-right: 34px;
        @include media-breakpoint-down(sm) {
          padding-right: 22px !important;
        }
        .title {
          white-space: pre-wrap;
          display: inline-flex;
          width: 50%;
        }
        .chords {
          display: inline;
          text-align: right;
          width: 50%;
          float: right;
        }
        @include media-breakpoint-down(xs) {
          .chords,
          .title {
            width: 100%;
          }
          .chords {
            float: unset;
          }
        }
      }
    }
    h3.songbook-title {
      font-size: 2.2rem;
      display: inline;
      font-weight: 600;
      line-height: 2.4rem;
      margin-right: 0;
    }
    .songbook-name {
      padding-bottom: 1rem;
    }
  }
  .songbooks {
    padding-top: 7.5rem;
    @include media-breakpoint-down(sm) {
      padding-top: 2rem;
    }
    h1 {
      font-family: "Playfair Display";
      font-size: 4.4rem;
      font-weight: 900;
      line-height: 4.8rem;
      text-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
      margin-bottom: 52px;
      &.songbook-title {
        margin-bottom: 20px;
      }
      @include media-breakpoint-down(sm) {
        font-size: 3.4rem;
      }
      .spinner-container {
        margin-left: 30px;
        display: inline !important;
      }
      .svg-plus-container {
        float: right;
        margin-right: 10px;
        margin-top: -12px;
        @include media-breakpoint-down(sm) {
          svg {
            width: 50px;
            height: 50px;
          }
        }
      }
    }
    .new-songbook {
      position: absolute;
      right: 27px;
      top: -11px;
      height: 60px;
      width: 60px;
      z-index: 10;
      cursor: pointer;
      .create-new-songbook {
        min-width: 200px;
        right: 0;
        position: absolute;
        #save {
          color: $white !important;
        }
      }
      button {
        color: transparent !important;
        @include media-breakpoint-down(sm) {
          height: 60px;
          padding-top: 0;
        }
        svg {
          g > path {
            fill: transparent;
          }
        }
      }
    }
    .back-btn {
      a {
        color: $gray-d2;
        font-weight: 300;
        font-size: 16px;
        border-bottom: transparent;
        text-decoration: none;
        &:hover {
          border-bottom: 2px solid $green;
          transition: border-bottom linear 0.3s;
        }
      }
    }
    .songbook-list-selector {
      font-size: 1.6rem;
      line-height: 2.2rem;
      text-transform: uppercase;
      color: $black;
      text-decoration: none;
      margin-right: 40px;
      &.active {
        position: relative;
        font-weight: 800;
        &::after {
          position: absolute;
          content: "";
          width: 30px;
          height: 2px;
          bottom: -11px;
          left: calc(50% - 15px);
          background-color: $red;
        }
      }
      @include media-breakpoint-down(sm) {
        font-size: 1.4rem;
        margin-right: 20px;
      }
      &:nth-last-child(1) {
        margin: 0px;
      }
    }
    .songbook {
      padding: 16px 0px 16px 25px;
      height: auto;
      position: relative;
      flex-wrap: nowrap;
      @include media-breakpoint-down(sm) {
        padding: 20px 0px 20px 25px;
        height: 140px;
        width: 100%;
        margin: 0 auto !important;
      }
      .songbook-songs {
        height: 45px;
        overflow: hidden;
      }
      .btn-group {
        position: relative;
        &:after {
          content: none;
          position: absolute;
          width: 1px;
          height: 100%;
          top: 20px;
          left: calc(50% + 2px);
          transform: translate(-50%);
          background-color: $gray-l1;
        }
        .hamburger-container {
          width: 21px;
          height: 21px;
          display: block;
          border-radius: 50%;
          position: relative;
          span {
            position: absolute;
            width: 15px;
            height: 1px;
            background-color: $gray-d2;
            left: 3px;
            top: 12.5px;
            &:nth-child(1) {
              top: 8.5px;
            }
          }
        }
        &.show {
          .hamburger-container {
            background-color: $gray-l1;
            span {
              &:nth-child(1) {
                top: 10px;
                transform: rotate(45deg);
              }
              &:nth-last-child(1) {
                top: 10px;
                transform: rotate(-45deg);
              }
            }
          }
        }
        .dropdown-menu {
          position: absolute !important;
          top: 32px !important;
          transform: translateX(calc(-100% + 40px)) !important;
          width: 200px !important;
          padding: 0px 16px !important;
          box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.15);
          border: none !important;
          &:after {
            content: "";
            border-color: $white transparent;
            border-style: solid;
            border-width: 0px 8px 8px 8px;
            height: 0px;
            width: 0px;
            top: 0px;
            right: 7px;
            position: absolute;
            transform: translateY(-100%);
          }
          .dropdown-item {
            font-size: 14px;
            line-height: 41px;
            padding: 0px;
            border-bottom: 1px solid $gray-l1;
            display: flex;
            position: relative;
            &:nth-last-child(1) {
              border: none;
            }
          }

          .dropdown-header,
          .dropdown-item {
            font-size: 14px;
            line-height: 35px;
            padding: 0px;
            border-bottom: 1px solid $gray-l1;
            display: flex;
            position: relative;
            justify-content: space-between;
            align-items: center;
            padding: 4px 0px;
            i.spinner {
              position: absolute;
              right: 2px;
              top: 14px;
            }
            &:nth-last-child(1) {
              flex-direction: column;
              align-items: flex-start;
              border: none;
            }
            .radio-container {
              display: flex;
              justify-content: space-between;
              width: 100%;
              padding-left: 5px;
              input {
                margin-right: 12px;
                position: relative;
                width: 10px;
                height: 10px;
                &:before {
                  content: "";
                  height: 20px;
                  width: 20px;
                  max-width: 20px;
                  border-radius: 50%;
                  cursor: pointer;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  border: solid 1px $gray-l1;
                  background-color: $white;
                }
                &:checked {
                  &:before {
                    border-color: $gray;
                  }
                  &:after {
                    content: "";
                    background-color: $red;
                    width: 12px;
                    height: 12px;
                    position: absolute;
                    border-radius: 50%;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                  }
                }
              }
            }
            svg {
              width: 20px;
            }
            .custom-control.custom-switch {
              right: 0px;
              top: 50%;
              transform: translateY(-50%);
              padding: 0px;
              width: 30px;
              height: 12px;
              position: absolute;
              .custom-control-input:checked ~ .custom-control-label::before {
                background-color: $blue;
              }
              .custom-control-input:checked ~ .custom-control-label::after {
                transform: translateX(100%);
              }
              label {
                width: 100%;
                height: 100%;
                &:before {
                  border: none;
                  left: 0px;
                  height: 12px;
                  width: 100%;
                  border-radius: 6px;
                  top: 50%;
                  transform: translateY(-50%);
                  background-color: $gray-l1;
                }
                &:after {
                  top: -1px;
                  left: 0px;
                  width: 16px;
                  height: 16px;
                  background-color: $gray;
                  border-radius: 50%;
                  transform: translateX(0%);
                }
              }
            }
          }
        }
      }
    }

    .songbook,
    .song {
      padding-left: 16.5px;
      border-top: 1px solid $gray-l1;
      position: relative;
      @include media-breakpoint-down(sm) {
        margin-right: -17px;
        margin-left: -17px;
      }
      .handle {
        position: absolute;
        position: absolute;
        top: 27px;
        left: 0;
        > .title-col {
          padding-left: 28px !important;
        }
        @include media-breakpoint-down(sm) {
          bottom: 63%;
          left: 2px;
        }
      }
      &.header {
        font-weight: bold;
      }
    }
    .songbook {
      overflow: visible;
    }
    .songbook-title {
      margin-top: 1rem;
      margin-bottom: 0.5rem;
      a,
      .upsell-modal-opener {
        color: $black;
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 2.4rem;
      }
      .upsell-modal-opener:hover {
        cursor: pointer;
        text-decoration: underline;
        text-decoration-color: $green-d1;
      }
    }
    .songbook-detail {
      h3.songbook-title {
        font-size: 2.2rem;
        display: inline;
        font-weight: 600;
        line-height: 6.4rem;
        margin-right: 0;
        & + button {
          border: none;
          background: transparent;
          cursor: pointer;
        }
        @include media-breakpoint-down(sm) {
          font-size: 2rem;
          font-weight: 700;
        }
      }
      .handle {
        top: 14px;
        height: 22px;
        width: 22px;
      }
      form.songbook-title {
        position: relative;
        @include media-breakpoint-down(sm) {
          padding-right: 12px;
        }
        input[type="text"] {
          font-size: 2.2rem;
          width: 95%;
          font-weight: 600;
          line-height: 2.4rem;
          border: none;
          border-bottom: 1px solid $blue;
          outline: none;
          padding-right: 30px;
          height: 33px;
          background: $white;
          margin: 0;
          @include media-breakpoint-down(md) {
            padding-right: 58px;
            font-size: 14px;
          }
        }
        input[type="submit"] {
          position: absolute;
          width: 45px;
          height: 20px;
          right: 55px;
          top: 50%;
          transform: translateY(-50%);
          border: none;
          padding-left: 0;
          font-size: 13px;
          @include media-breakpoint-down(md) {
            font-size: 11px;
            width: 40px;
          }
        }
      }
      .song {
        height: auto;
        padding-top: 0;
        min-height: 55px;
        margin: 0 auto;
      }
      .song-title {
        a {
          color: $black;
          font-weight: 600;
          line-height: 2.4rem;
        }
      }
      .chord-selector {
        height: 30px;
        font-size: 1.5rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 95%;
      }
      .songbook-filter {
        span {
          margin-top: 5px;
        }
        .sort-selector {
          height: 30px;
          font-size: 1.5rem;
          min-width: 100%;
        }
      }
    }
    .btn-light {
      &:hover,
      &:focus,
      &:active {
        background-color: transparent;
        border-color: transparent;
        box-shadow: none !important;
        svg path {
          fill: $red;
        }
      }
      @include media-breakpoint-down(sm) {
        margin: 4px;
      }
      svg {
        height: 18px;
      }
      &.dropdown-btn {
        svg {
          width: 30px;
        }
      }
    }
  }
  .button-modal-songbook {
    &:hover {
      svg path {
        fill: $red;
      }
    }
    svg {
      height: 32px;
    }
  }
  .actions {
    display: flex !important;
    text-align: right;
    justify-content: flex-end;
    cursor: pointer;
    .favoured {
      svg path {
        fill: $red;
      }
    }
    .heart {
      svg {
        height: 20px;
      }
    }
    @include media-breakpoint-down(sm) {
      display: flex;
      padding-top: 4px;
    }
    .modal-opener {
      border: 0px;
      background: transparent;
      margin-left: 3px;
      margin-right: 3px;
      position: relative;
      padding: 3.75px 7.5px;
      cursor: pointer;
      .icon {
        svg {
          height: 24px;
        }
      }
    }
    .btn-light {
      &:hover {
        background-color: transparent;
        border: none;
      }
      @include media-breakpoint-down(sm) {
        margin: 4px;
      }
      svg {
        height: 18px;
      }
      &.dropdown-btn {
        svg {
          width: 30px;
        }
      }
    }
    button.btn,
    .btn-group button.btn {
      background: transparent;
      border: none;
      height: 32px;
      width: auto;
      position: relative;
      margin-left: 3px;
      margin-right: 3px;
      outline: none;
      box-shadow: none;
    }
  }
  input.private,
  input.public {
    height: 48px;
    background-color: $white-d1;
    border: none;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  input.public {
    top: -32px;
  }
}
