.ReactModal__Overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: scroll;

    @media (max-width: 1440px) {
        .nav {
            padding: 31px 40px 0;
        }
    }

    @include media-breakpoint-down(md) {
        z-index: 100 !important;
        margin-top: -8px;
    }
}

.modal-qr {
    border-radius: 16px;
    width: 50vw;
    position: relative;
    padding: 50px;
    max-width: 865px;
    height: auto;
    max-height: 650px;
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.15);
    background-color: $white;
    z-index: 10;
    overflow: hidden;
    border: $black;

    @include media-breakpoint-down(md) {
        width: 90vw;
        height: fit-content !important;
    }

    .header-container {
        flex-direction: row;
        position: relative;

        h2 {
            font-family: "Playfair Display";
            font-size: calc(24px + (40 - 24) * ((100vw - 300px) / (1920 - 300)));
            line-height: 48px !important;
            font-weight: 900;
            color: $gray-d2;
            margin-bottom: 20px;
            color: #2B2B2B !important;
        }

        button.close {
            width: 40px;
            height: 40px;
            opacity: 1;
            z-index: 100;
            position: absolute;
            top: -27px;
            right: -30px;
            border-radius: 50%;
            background-color: $green;

            .close-icon {
                left: 26%;
                top: 19px;
                position: absolute;

                span {
                    width: 20px;
                    height: 2.5px;
                    display: block;
                    margin-bottom: 6px;
                    z-index: 2;
                    background-color: $white;
                    position: absolute;


                    &:nth-child(1) {
                        transform: rotate(45deg);
                    }

                    &:nth-last-child(1) {
                        transform: rotate(-45deg);
                    }
                }
            }

        }
    }

    .qr-container {
        max-width: 380px;
        margin: 20px auto;
        padding: 0 40px;

        .qr-inner-container {
            width: 100%;
            padding: 20px;
        }

        .figure-text {
            color: #2B2B2B !important;
            margin-top: 26px;
            text-align: center;
        }

        h2 {
            font-family: "Playfair Display";
            font-size: calc(24px + (40 - 24) * ((100vw - 300px) / (1920 - 300)));
            line-height: 48px !important;
            font-weight: 900;
            color: $gray-d4;
            margin-bottom: 20px;
        }

        ul {
            margin-bottom: 50px;

            @include media-breakpoint-down(md) {
                margin-bottom: 40px;
            }

            li {
                font-size: 20px;
                line-height: 50px;
                list-style: none;
                position: relative;

                @include media-breakpoint-down(md) {
                    line-height: 40px;
                    font-size: 18px;
                }

                svg {
                    position: absolute;
                    left: -33px;
                    top: 16px;
                }
            }
        }

        span {
            font-size: 20px;
            color: #2B2B2B !important;

            @include media-breakpoint-down(md) {
                font-size: 18px;
            }

            &.price {
                font-weight: 700;
            }
        }

        .button-container {
            text-align: center;

            .button-modal-subscription,
            .button-modal-login {
                margin-top: 40px;

                @include media-breakpoint-down(md) {
                    margin-top: 20px;
                }
            }
        }
    }
}

body.dark-mode {
    button.close {
        background-color: $red !important;

        .close-icon {
            span {
                background-color: $gray-d2;
            }
        }
    }

    .modal-qr {
        background-color: $chord-color-black;

        .qr-container {
            padding: 0 20px;

            .qr-inner-container {
                background-color: black;
                width: 100%;
                padding: 20px;
            }

            .figure-text {
                color: $dark-mode-white !important;
            }

        }
    }
}