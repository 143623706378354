div.favourites-div{
    .favourites-list{
        .song-add-to-songbook{
            &.open{
                ~ .song-list{
                    display: none;
                }
            }
            ul{
                li{
                    svg{
                        height: 11px;
                    }
                }
            }
        }
        .song-list{
            @include media-breakpoint-down(md){
                .item{
                    display: flex;
                    justify-content: space-between;
                    height: auto;
                    min-height: 40px;
                    padding: 0;
                    align-items: center;
                    label{
                        order: 2;
                        margin-left: auto;
                        margin-right: 12px;
                    }
                    label, .actions{
                        position: relative;
                        right: unset !important;
                        top: unset !important;
                        left: unset !important;
                    }
                    a{
                        order: 1;
                    }            
                    .actions{
                        order: 3;
                    }
                    a .title{
                        white-space: break-spaces;
                        padding-right: 42px;
                    }            
                }
            }
        }
    }
}
.song-list{
    &.Author {
        height: 50px;
    }
    &.Song {
        height: 112px;
        &.hide{
            z-index: -1;
            position: relative;
        }
        @include media-breakpoint-down(sm){
            overflow: hidden;
        }
    }
    .song-add-to-songbook-container {
        display: flex;
        align-items: center;
        width: 100%;
        border-radius: 0px;
        background-color: transparent;
        .song-add-to-songbook {
            opacity: 1;
            width: auto;
            background-color: $green;
            width: 100%;
            border-radius: 4px;
            height: 100%;
            button {
                height: 100%;
                // margin-left: 10px;
                background-color: transparent;
                display: flex;
                align-items: center;
                padding: 8px 20px;
                span {
                    order: 1;
                    color: white;
                    font-size: 18px;
                    font-weight: bold;
                    line-height: 24px;
                    @include media-breakpoint-down(sm){
                        font-size: 14px;
                    }
                }
                svg {
                    width: 20px;
                    height: 20px;
                    position: static;
                    transform: translateY(0px);
                    margin-right: 13px;
                }
            }
            .songbooks {
                border: none;
                padding-top: 12px;
            }
        }
    }

    .filter-info {
        padding-bottom: 10px;
        @include media-breakpoint-down(sm){
            display: inline-block;
        }
        a {
            font-weight: 600;
            color: $green;
            &:hover {
                text-decoration: none;
            }
        }
    }

    .item{
        padding: 15px 105px 15px 34px;
        position: relative;
        border-top: 1px solid $gray-l1;
        position: relative;
        @include media-breakpoint-down(md) {
            padding: 15px 80px 15px 50px;
        }
        @include media-breakpoint-down(sm){
            padding: 12px 50px 12px 22px !important;
            min-width: 100vw;
            position: relative;
            label{
                left: unset !important;
                right: 15px;
            }
        }
        .actions {
            position: absolute;
            right: 20px;
            top: 20px;
            display: flex;
            @include media-breakpoint-down(sm){
                right: 15px;
                top: 45px;
            }
            a {
                cursor: pointer;
                svg {
                    width: 18px;
                    height: 16px;
                }
            }
            .favoured svg path{
              fill: $red;
            }
        }
        label {
            position: absolute;
            top: 17px;
            left: 0;
            width: 20px;
            height: 20px;
            margin-bottom: 0px;
            border-radius: 2px;
            border: 1px solid $gray-d1;
            @include media-breakpoint-down(md) {
                left: 15px;
            }
            &:after{
                height: 12px;
                width: 6px;
                display: block;
                transform: rotate(45deg);
                border-right: 2px solid $white;
                border-bottom: 2px solid $white;
                position: absolute;
                left: 7px;
                top: 2px;
            }
        }
        input[type="checkbox"]{
            position: absolute;
            display: none;
            &:checked + label {
                background-color: $blue;
                border: none;
                &:after {
                    content: "";
                }
            }
        }
        a{
            text-decoration: none;
            .author{
              color: $green;
              font-weight: 600;
              font-size: 1.5rem;
            }
        }
        .title{
            color: $black;
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
            white-space: nowrap;
            @include media-breakpoint-down(sm){
                font-size: 16px !important;
            }
            span{
                color: $blue;
            }
        }

        .song-excerpt{
            font-weight: 300;
            font-size: 1.5rem;
            color: $gray-d1;
            overflow: hidden;
            width: 100%;
            height: 60px;
            line-height: 1.3;
            @include media-breakpoint-down(md){
                font-size: 13px;
                height: 46px;
            }
            span{
                color: $green;
            }
        }

    }
}

body.dark-mode .song-list{
    .item{
        .title{
            color: $gray;
        }
    }
}

.search-wrapper {
    position: relative;
    .search-results {
        &>.song-list {
            &>.song-list {
                &:nth-child(1) {
                    .item {
                        border-top: none;
                    }
                }
            }
        }
    }
}